import React from "react";
import Style from "./TableControlActions.module.css";
import FreeTextSearch from "./features/free-text-search";
import TableDisplayMode from "./features/table-display-mode";
import ExportCsv from "./features/export-csv";
import TableViewTypeControl from "./features/table-view-type-control";
import DisplayColumnControl from "./features/display-column-control";
import FeatureButtons from "./features/feature-buttons";

export const TableControlActions = () => {
  // TODO: commented out TableDisplayMode for now until gridcard is back

  return (
    <div className={Style.container}>
      <div className={Style.row}>
        <FeatureButtons />
        <FreeTextSearch />
        {/* <TableDisplayMode /> */}
        <ExportCsv />
      </div>
      <div className={Style.row}>
        <TableViewTypeControl />
        <DisplayColumnControl />
      </div>
    </div>
  );
};
