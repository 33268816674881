import React, { useState, useEffect, useMemo, useCallback } from "react";
import { CardBody, Input } from "mdbreact";
import { Modal, Alert } from "react-bootstrap";
import { isValidPhoneNumber as isValidPhoneNumberVerifier } from "react-phone-number-input";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { useRoleAuthorization } from "apps/hooks/useRoleAuthorization";
import { useAuthenticationContext } from "context/authentication-context/AuthenticationContext";
import Amplify from "aws-amplify";
import PasswordInputFormWithValidation from "features/password-form-with-input-validation/PasswordInputFormWithValidation";
import { XemelgoService } from "../../services/XemelgoService";
import { SessionStorageService } from "../../services/session-storage-service";
import { useXemelgoAppsyncClient } from "../../services/xemelgo-appsync-service";
import UserProfilePageStyle from "./UserProfilePage.module.css";
import ProfileForm from "../../components/ProfileForm";
import AlertService from "../../services/AlertService";
import ConfigurationService from "../../services/ConfigurationService";
import { LocalCacheService } from "../../services/local-cache-service";
import { UserProfile } from "../../domains/user-profile";
import ScreenFrame from "../../components/screen-frame";
import xemelgoStyle from "../../styles/variable";
import useMixpanelContext from "../../context/mixpanel-context";
import {
  USER_PROFILE_PAGE_EVENT,
  USER_PROFILE_PAGE_EVENT_STEPS
} from "../../constants/mixpanel-constant/userProfilePage";

const title = "Your Profile";
const mainColor = xemelgoStyle.theme.XEMELGO_BLUE;
const secondaryColor = xemelgoStyle.theme.XEMELGO_LIGHTBLUE;
const attributeNameToProfileNameMap = {
  given_name: "givenName",
  family_name: "familyName",
  email: "email",
  phone_number: "phone",
  "custom:profile_image_url": "imageOptional"
};

/**
 * This method contains the knowledge of how data is stored in the state.
 *  It compares the default value and newly entered value and construct proper payload if value has changed.
 * @param currentPayload
 * @param currentUserInformation
 * @return {*}
 */
const buildUpdateAttributePayload = (currentPayload, currentUserInformation) => {
  // reverse the map: profile name attribute name
  const nameMap = Object.keys(attributeNameToProfileNameMap).reduce((map, key) => {
    const value = attributeNameToProfileNameMap[key];
    map[value] = key;
    return map;
  }, {});

  const profileFieldNames = Object.keys(nameMap);

  // compare new and existing value, only update when new value is different than existing one
  let payload = null;
  profileFieldNames.forEach((field) => {
    let newValue = currentPayload[field];

    if (!newValue) {
      return;
    }

    newValue = newValue.trim();
    const existingValue =
      currentUserInformation[field] && typeof currentUserInformation[field] !== "object"
        ? currentUserInformation[field].trim()
        : "";
    if (newValue === existingValue) {
      return;
    }

    // only create payload if there is at least one field has changed
    if (!payload) {
      payload = {};
    }
    const attributeName = nameMap[field];
    payload[attributeName] = newValue;
  });

  return payload;
};

const mapSessionInfoToProfileInfo = (sessionInfo) => {
  const profileInfo = {};
  const attributeMap = sessionInfo.attributes;

  if (attributeMap) {
    const nameMap = attributeNameToProfileNameMap;
    Object.entries(nameMap).forEach(([attributeName, profileName]) => {
      profileInfo[profileName] = attributeMap[attributeName];
    });
  }

  return profileInfo;
};

/**
 * React's component simple form
 * @param className
 * @param disabled
 * @param value
 * @param label
 * @param id
 * @param onInput
 * @param type
 * @param hint
 * @return {*}
 * @constructor
 */

const EDITABLE_FIELD_STATE = {
  givenName: ["admin", "vendorClientAdmin"],
  familyName: ["admin", "vendorClientAdmin"],
  email: false,
  phone: true
};

const UserProfilePage = () => {
  const [currentProfileInfo, setCurrentProfileInfo] = useState({});
  const [payload, setPayload] = useState({});
  const [currentPassword, setCurrentPassoword] = useState("");
  const [inputEnabled, setInputEnabled] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
  const [imageOptional, setImageOptional] = useState();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [config, setConfig] = useState({});
  const [testMode] = useState(SessionStorageService.getTestMode() === "Test");
  const xemelgoAppsyncClient = useXemelgoAppsyncClient();
  const [userClient] = useState(xemelgoAppsyncClient.getUserClient());
  const { sendMixPanelEvent } = useMixpanelContext();

  const { isUserInRoles } = useRoleAuthorization({});

  const { changePassword, updateAttributes, cognitoUser } = useAuthenticationContext();

  const isFederatedSignInUser = useMemo(() => {
    return cognitoUser.storage["amplify-signin-with-hostedUI"] === "true";
  }, [cognitoUser]);

  useEffect(() => {
    sendMixPanelEvent(USER_PROFILE_PAGE_EVENT, USER_PROFILE_PAGE_EVENT_STEPS.ENTRY);
    fetchUserProfile();
  }, []);

  const switchMessage = useMemo(() => {
    if (testMode) {
      return "You are about to switch back to the production instance. The page will be refreshed and you will no longer see test data.";
    }
    return "You are about to switch to the test instance. The page will be refreshed and you will no longer see production data.";
  }, [testMode]);

  const testModeEnabled = useMemo(() => {
    const userProfile = LocalCacheService.loadUserProfile();
    const testModeConfig = config?.configData?.webClient?.testMode;
    return testModeConfig && Object.keys(testModeConfig).length
      ? testModeConfig.enabled && testModeConfig.roles.includes(userProfile.getRole())
      : testModeConfig && userProfile.isUserSuperAdmin();
  }, [config]);

  const profileImage = useMemo(() => {
    return currentProfileInfo.imageOptional
      ? currentProfileInfo.imageOptional
      : require("../../img/default_user_profile.png");
  }, [currentProfileInfo]);

  const fetchUserProfile = async () => {
    const userProfile = LocalCacheService.loadUserProfile();
    const profileInfo = mapSessionInfoToProfileInfo(userProfile);
    const clientConfig = await ConfigurationService.getConfiguration();

    setCurrentProfileInfo({ ...profileInfo });
    setPayload({});
    setInputEnabled(false);
    setConfig(clientConfig);
  };

  const resolveEditableState = (fieldName) => {
    const editableStateForField = EDITABLE_FIELD_STATE[fieldName];

    if (Array.isArray(editableStateForField)) {
      return isUserInRoles(editableStateForField);
    }

    return typeof editableStateForField === "boolean" ? editableStateForField : true;
  };

  /**
   * handle input change event
   * @param event
   */
  const handleChange = (id, value) => {
    setPayload({
      ...payload,
      [id]: value
    });
  };

  const handleChangeCurrentPassword = (event) => {
    setCurrentPassoword(event.target.value);
  };

  /**
   * Open password change modal
   * @param event
   * @return {Promise<void>}
   */
  const handleChangePasswordModal = async (event) => {
    event.preventDefault();
    setIsModal(true);
  };

  /**
   * Toggle password change modal
   * @return {Promise<void>}
   */
  const toggleModal = async () => {
    setIsModal(!isModal);
  };

  const toggleTestModal = () => {
    setShowTestModal(!showTestModal);
  };

  /**
   * Switch to edit profile mode
   * @param event
   * @return {Promise<void>}
   */
  const handleSubmitEditProfile = async (event) => {
    event.preventDefault();
    if (!imageOptional || !imageOptional.trim()) {
      setImageOptional(require("../../img/default_user_profile.png"));
    }
    setInputEnabled(true);
  };

  /**
   * Cancel the edit form
   * @param event
   * @return {Promise<void>}
   */
  const handleCancelEditProfile = async (event) => {
    event.preventDefault();
    setInputEnabled(false);
    setPayload({});
  };

  /**
   * Update profile if there is any change made
   * @param event
   * @return {Promise<void>}
   */
  const handleSubmitChangeProfile = async (event) => {
    event.preventDefault();

    // validate phone number format
    if (payload.phone && payload.phone.trim()) {
      const phoneNumber = payload.phone.replace(/\D/g, "");
      if (phoneNumber.length < 10) {
        alert(`Incorrect phone number format ${payload.phone}. Phone number must contains country code and area code.`);
        return;
      }
    }
    const payloadToSubmit = buildUpdateAttributePayload(payload, currentProfileInfo);
    if (!payloadToSubmit) {
      // when there is no change in profile, do nothing
      return;
    }
    try {
      // Update the name on the backend

      if (payload.givenName || payload.familyName) {
        const currentUserResult = await userClient.queryUser();
        await userClient.updateUserNames(currentUserResult.queryUser.id, payload.givenName, payload.familyName);
      }

      await updateAttributes(payloadToSubmit);
      const updatedSessionInfo = await Amplify.Auth.currentAuthenticatedUser();
      const updateProfile = UserProfile.parse(updatedSessionInfo);
      LocalCacheService.saveUserProfile(updateProfile);
      const userProfile = LocalCacheService.loadUserProfile();
      if (payloadToSubmit.phone_number && isUserInRoles(["admin"])) {
        const result = await AlertService.getNotificationRecipients();
        const emailId = result ? result.getEmails() : userProfile.getEmail();
        const newPayload = {};
        if (isEmail(emailId)) {
          newPayload.email = emailId;
        }
        newPayload.phoneNumber = payloadToSubmit.phone_number;
        await AlertService.updateNotificationSubscription(newPayload);
      }
      await fetchUserProfile();
    } catch (err) {
      alert(`Something is wrong: ${err.message}`);
      console.error(err);
    }
  };

  /**
   * validate and ensure new password and confirm new password match
   * @return {string|boolean}
   */
  const isValidPasswordForm = () => {
    return (
      payload.currentPassword &&
      payload.currentPassword.length > 0 &&
      payload.newPassword &&
      payload.newPassword.length > 0 &&
      payload.newPassword === payload.confirmNewPassword
    );
  };

  /**
   * Create a top banner indicating operation succeeds
   * @return {*}
   */
  const createPasswordChangeSuccessBanner = () => {
    return (
      <Alert
        className={UserProfilePageStyle.change_password_alerts}
        variant="success"
        onClose={handleDismiss}
        dismissible
      >
        <h4>Success!</h4>
        <p>Your password has been changed!</p>
      </Alert>
    );
  };

  /**
   * Handle cancellation of password change operation
   */
  const handleDismiss = () => {
    setPasswordChangeSuccess(false);
    setPayload({});
  };

  const canBeSubmitted = () => {
    const errors = validateCreateUserForm();
    const isDisabled = Object.keys(errors).some((x) => {
      return errors[x];
    });
    return !isDisabled;
  };

  const shouldMarkError = (field) => {
    const errors = validateCreateUserForm();
    const hasError = errors[field];
    return hasError;
  };

  const validatePhoneNumber = () => {
    let valid = true;
    if (payload.phone) {
      valid = isValidPhoneNumberVerifier(payload.phone);
    }
    setIsValidPhoneNumber(valid);
    return valid;
  };

  const isEmail = (email) => {
    return /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+).([a-zA-Z]{2,5})$/g.test(email);
  };

  const validateCreateUserForm = () => {
    return {
      givenName: false,
      familyName: false,
      username: /\s/g.test(payload.username),
      email: payload.email && !isEmail(payload.email),
      phone: payload.phone && !isValidPhoneNumber
    };
  };

  const handleTestSwitch = () => {
    const currentMode = SessionStorageService.getTestMode();
    let nextMode;
    if (currentMode === "Prod") {
      nextMode = "Test";
      // switches scenario to 1 which makes xemelgo-client query test data
      XemelgoService.getClient().setScenario("1");
    } else {
      nextMode = "Prod";
      XemelgoService.getClient().setScenario("0");
    }
    SessionStorageService.setTestMode(nextMode);
    window.location.reload();
  };

  const renderSwitchIcon = () => {
    return (
      <div
        onClick={toggleTestModal}
        className={UserProfilePageStyle.swap_button}
      >
        <SwapHorizIcon />
      </div>
    );
  };

  const resolvedEditableStates = {
    givenName: resolveEditableState("givenName"),
    familyName: resolveEditableState("familyName"),
    email: resolveEditableState("email"),
    phone: resolveEditableState("phone")
  };

  const onSubmit = useCallback(
    async (newPassword) => {
      try {
        await changePassword(currentPassword, newPassword);
        await toggleModal();
        setPasswordChangeSuccess(true);
        setCurrentPassoword("");
        setPayload({});
      } catch (err) {
        if (err.message === "Incorrect username or password.") {
          alert("Incorrect Current Password Provided");
        } else {
          alert(err.message);
        }
      }
    },
    [currentPassword]
  );

  return (
    <>
      {passwordChangeSuccess === true && createPasswordChangeSuccessBanner()}
      <ScreenFrame
        title={title}
        color={mainColor}
        secondaryColor={secondaryColor}
        titleIconComponent={
          <AccountBoxIcon
            width={27}
            height={27}
            style={{ color: mainColor }}
          />
        }
        titleRightComponent={testModeEnabled && renderSwitchIcon()}
      >
        <div className={UserProfilePageStyle.main_card}>
          <div className={UserProfilePageStyle.responsive_container}>
            <img
              src={profileImage}
              alt="profile pic"
            />
          </div>
          <CardBody>
            <div>
              <ProfileForm
                clean={!inputEnabled}
                handleChange={handleChange}
                shouldMarkError={shouldMarkError}
                hint={currentProfileInfo}
                disabled={!inputEnabled}
                editable={resolvedEditableStates}
                validatePhoneNumber={validatePhoneNumber}
              />
            </div>

            {inputEnabled && (
              <div className={UserProfilePageStyle.profile_form_buttons}>
                <button
                  className="cancel-button"
                  onClick={handleCancelEditProfile}
                >
                  Cancel
                </button>
                <button
                  disabled={!canBeSubmitted()}
                  className="default-button"
                  onClick={handleSubmitChangeProfile}
                >
                  Save
                </button>
              </div>
            )}

            {!inputEnabled && !isFederatedSignInUser && (
              <div className={UserProfilePageStyle.profile_form_buttons}>
                <div
                  onClick={handleChangePasswordModal}
                  className={UserProfilePageStyle.change_password}
                >
                  Change Password
                </div>
                <button
                  type="submit"
                  onClick={handleSubmitEditProfile}
                  className="default-button"
                >
                  Edit Profile
                </button>
              </div>
            )}
          </CardBody>

          <Modal
            show={showTestModal}
            onHide={toggleTestModal}
          >
            <Modal.Header closeButton>
              <Modal.Title className={UserProfilePageStyle.change_password_title}>Switching Modes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{switchMessage}</div>
              <div>Press confirm to proceed</div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="cancel-button"
                onClick={toggleTestModal}
              >
                Cancel
              </button>
              <button
                className="default-button"
                onClick={handleTestSwitch}
              >
                Confirm
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={isModal}
            onHide={toggleModal}
          >
            <Modal.Header closeButton>
              <Modal.Title className={UserProfilePageStyle.change_password_title}>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={UserProfilePageStyle.form_group}>
                <p
                  style={{
                    textAlign: "center",
                    color: "black"
                  }}
                >
                  Please enter your old password followed by your new password.
                </p>
                <Input
                  className={UserProfilePageStyle.input_password}
                  label="Current Password"
                  id="currentPassword"
                  onInput={handleChangeCurrentPassword}
                  type="password"
                />
                <PasswordInputFormWithValidation
                  onSubmit={onSubmit}
                  submitButtonText="Submit"
                  cancelButtonText="Cancel"
                  onCancel={toggleModal}
                  classOverrides={{
                    showPasswordToggleIcon: UserProfilePageStyle.show_password_toggle_icon,
                    showPasswordToggleLabel: UserProfilePageStyle.show_password_toggle_label,
                    passwordInput: UserProfilePageStyle.password_input,
                    passwordRequirementLabelColor: UserProfilePageStyle.password_requirement_label_color
                  }}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </ScreenFrame>
    </>
  );
};

export default UserProfilePage;
