import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom-v5-compat";
import { QueryDetailsFn } from "../../data/types";
import useDetailsPageConfigContext from "../details-page-config-context";
import { DetailsCardData } from "../../features/details-card/data/types";

type DetailsPageStateContextType = {
  id: string;
  isLoading: boolean;
  featureMap: Record<string, JSX.Element>;
  detailsCardData: DetailsCardData | null;
};

const defaultState: DetailsPageStateContextType = {
  id: "",
  isLoading: true,
  featureMap: {},
  detailsCardData: null
};

const DetailsPageStateContext = createContext<DetailsPageStateContextType>(defaultState);

export const useDetailsPageStateContext = () => {
  return useContext(DetailsPageStateContext);
};

export const DetailsPageStateContextProvider = ({
  children,
  solutionFeatureMap,
  onQueryDetails
}: {
  children: React.ReactNode;
  solutionFeatureMap: Record<string, JSX.Element>;
  onQueryDetails: QueryDetailsFn;
}) => {
  const { additionalAttributes, detailsCard } = useDetailsPageConfigContext();
  const [searchParams] = useSearchParams();
  const [id] = useState<string>(searchParams.get("id") || searchParams.get("itemId") || "");
  const [isLoading, setIsLoading] = useState(true);
  const [detailsCardData, setDetailsCardData] = useState<DetailsCardData | null>(null);

  const featureMap = useMemo(() => {
    return {
      ...solutionFeatureMap
      // TODO: add default features
    };
  }, [solutionFeatureMap]);

  useEffect(() => {
    queryDetails();
  }, [id, additionalAttributes, detailsCard]);

  const queryDetails = async () => {
    if (id) {
      setIsLoading(true);
      setDetailsCardData(await onQueryDetails(id, additionalAttributes, detailsCard));
      setIsLoading(false);
    }
  };

  return (
    <DetailsPageStateContext.Provider
      value={{
        id,
        isLoading,
        featureMap,
        detailsCardData
      }}
    >
      {children}
    </DetailsPageStateContext.Provider>
  );
};
