/**
 * Query for Items from the given tags. This method may return a subset of the requested tags
 *  as it only returns tags that are tracked as item (with type) in the system
 * @param newTags { any[] }
 * @param inventoryClient { any }
 * @param additionalQueryAttributes { any }
 * @return { Promise<Object.<string, ReducedItem> }
 */
export const queryItemsFromBasicTags = async (
  tagData,
  inventoryClient,
  additionalQueryAttributes = {},
  itemClassFilters = []
) => {
  const { itemType: itemTypeFields = [], item: itemFields = [] } = additionalQueryAttributes;
  const newTags = [
    ...new Set(
      tagData.map((tag) => {
        return tag.vid;
      })
    )
  ];
  if (!newTags.length) {
    return Promise.resolve({});
  }

  /**
   * @type { InventoryVerificationData[] | null}
   */
  const results = await inventoryClient.getInventoryVerificationDataByVids(
    newTags,
    itemFields,
    itemTypeFields,
    itemClassFilters
  );

  if (!results) {
    return Promise.resolve({});
  }

  const firstSeenTimestamp = Date.now();
  const newItemByTag = results
    .map((raw) => {
      const trackingSession = raw.associatedWithTrackingSession?.[0];
      const { ofType = [] } = trackingSession?.tracksItem?.[0] || {};
      let type;
      if (ofType.length) {
        const { id, identifier, description, ...tenantProperties } = ofType[0];
        type = {
          id,
          identifier,
          description,
          ...tenantProperties
        };
      }

      const item = raw.associatedWithTrackingSession?.[0]?.tracksItem?.[0];
      return {
        ...item,
        vid: raw.vid,
        sensorProfileId: raw.id,
        itemId: item?.id,
        itemTypeId: ofType[0]?.id,
        trackingSessionId: trackingSession?.id,
        type,
        firstSeenTimestamp
      };
    })
    .filter((item) => {
      return item.itemId && item.type;
    })
    .reduce((map, item) => {
      const clonedMap = { ...map };
      clonedMap[item.vid] = item;
      return clonedMap;
    }, {});

  return newItemByTag;
};
