import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import Style from "./PaginatedListTableWithTypes.module.css";
import useSortableHeader from "../../hooks/use-sortable-header";
import PaginatedList from "../PaginatedList/PaginatedList";
import { SORT_DIRECTION } from "../../data/constants";
import { BUTTON_HEADER_ID, VALUE_TYPE_MAP } from "./data/constants";
import TableRow from "./components/table-row";
import type { Header, Item } from "./data/types";

export interface PaginatedListTableWithTypesProps {
  headers: Header[];
  dataList: Record<string, unknown>[];
  getTitleURLCallbackFn?: (item: any, headerId: string) => string;
  isLoading?: boolean;
  sortIdReplacementMap?: any;
  columnSortDisabled?: boolean;
  renderHeader?: (header: any) => JSX.Element;
  renderItem?: (item: any) => JSX.Element;
  headerRowContainerClassName?: string;
  headerCellContainerClassName?: string;
  itemCellContainerClassName?: string;
  itemRowContainerClassName?: string;
  emptyListContainerClassName?: string;
  selectable?: boolean;
  showRowOptions?: boolean;
  numItemsPerPage?: number;
  onRowOptionClick?: (item: any, option: any) => void;
  isExpandable?: boolean;
  [key: string]: any;
}

export const PaginatedListTableWithTypes = ({
  headers = [],
  dataList = [],
  getTitleURLCallbackFn = () => {
    return "";
  },
  isLoading,
  sortIdReplacementMap = {},
  columnSortDisabled,
  renderHeader,
  renderItem,
  headerRowContainerClassName,
  headerCellContainerClassName,
  itemCellContainerClassName,
  itemRowContainerClassName,
  emptyListContainerClassName,
  selectable,
  showRowOptions,
  isExpandable,
  onRowOptionClick = () => {},
  numItemsPerPage = 10,
  ...restProps
}: PaginatedListTableWithTypesProps) => {
  const { defaultOrderBy, defaultOrderDirection } = useMemo(() => {
    if (columnSortDisabled || !headers.length) {
      return {};
    }

    const defaultHeader =
      headers.find((eachHeader) => {
        return eachHeader.defaultSort;
      }) || headers[0];

    return {
      defaultOrderBy: defaultHeader.id,
      defaultOrderDirection: defaultHeader.defaultDirection || SORT_DIRECTION.ascending
    };
  }, [headers, columnSortDisabled]);

  const { order, orderBy, getSortedData, getSortableHeader } = useSortableHeader(defaultOrderDirection, defaultOrderBy);

  const sortedDataList = useMemo(() => {
    return columnSortDisabled ? dataList : getSortedData(dataList, sortIdReplacementMap[orderBy]);
  }, [dataList, order, orderBy, sortIdReplacementMap, columnSortDisabled]);

  const LoadingComponent = () => {
    const numColumns = headers?.length || 3;
    return (
      <div
        className={`${Style.flex_row} ${
          selectable ? Style.table_item_selectable : Style.table_item
        } ${itemRowContainerClassName}`}
      >
        {[...Array(numColumns)].map((_, index) => {
          return (
            <div
              key={index}
              className={`${Style.flex_row} ${Style.table_column} ${itemCellContainerClassName}`}
            >
              <Skeleton
                containerClassName={Style.loading_icon}
                height={24}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const headersWithButtons = useMemo(() => {
    const newHeaders = [...headers];

    if (showRowOptions) {
      newHeaders.push({ id: BUTTON_HEADER_ID, type: "options" });
    }
    if (isExpandable) {
      newHeaders.unshift({ id: BUTTON_HEADER_ID, type: "expand" });
    }

    return newHeaders;
  }, [headers, showRowOptions, isExpandable]);

  return (
    <PaginatedList
      numItemsPerPage={numItemsPerPage}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      selectable={selectable}
      data={sortedDataList}
      header={headersWithButtons}
      headerContainerClassName={`${Style.table_header} ${headerRowContainerClassName}`}
      renderEmptyList={() => {
        if (isLoading) {
          return [...Array(numItemsPerPage)].map((_, index) => {
            return <LoadingComponent key={index} />;
          });
        }
        return (
          <div className={`${Style.flex_row} ${Style.empty_list_container} ${emptyListContainerClassName}`}>
            <p className={Style.empty_list_text}>No data available</p>
          </div>
        );
      }}
      renderHeader={
        renderHeader ||
        ((eachHeader: Header) => {
          if (eachHeader.id === BUTTON_HEADER_ID) {
            return (
              <div
                key={BUTTON_HEADER_ID}
                className={Style.option_icon_container}
              />
            );
          }
          return getSortableHeader(eachHeader, {
            headerContainerClass: `${Style.flex_row} ${Style.table_column} ${headerCellContainerClassName} ${
              eachHeader.type === VALUE_TYPE_MAP.comment && Style.comment_column
            }`,
            className: Style.table_header_text,
            disabled: columnSortDisabled
          });
        })
      }
      renderItem={(eachItem: Item) => {
        if (isLoading) {
          return <LoadingComponent />;
        }

        if (renderItem) {
          return renderItem(eachItem);
        }

        return (
          <TableRow
            key={eachItem.id}
            headers={headers}
            item={eachItem}
            selectable={selectable}
            itemRowContainerClassName={itemRowContainerClassName}
            showRowOptions={showRowOptions}
            isExpandable={isExpandable}
            onRowOptionClick={onRowOptionClick}
            itemCellContainerClassName={itemCellContainerClassName}
            getTitleURLCallbackFn={getTitleURLCallbackFn}
          />
        );
      }}
    />
  );
};
