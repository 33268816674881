import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ScreenFrame from "../../components/screen-frame";
import useDetailsPageConfigContext from "./contexts/details-page-config-context";
import Style from "./DetailsPage.module.css";
import DetailsPageTabs from "./features/details-page-tabs";
import DetailsCard from "./features/details-card";
import useDetailsPageStateContext, { DetailsPageStateContextProvider } from "./contexts/details-page-state-context";
import { DetailsPageProps } from "./data/types";

const DetailsPageFeature = ({
  defaultTitle,
  editEnabled,
  primaryColor,
  secondaryColor,
  iconComponent: IconComponent
}: DetailsPageProps) => {
  const location = useLocation();
  const history = useHistory();

  const { id, detailsCardData } = useDetailsPageStateContext();
  const { customText } = useDetailsPageConfigContext();

  useEffect(() => {
    if (!id) {
      // TODO: Go back to solution main page
      history.push("/");
    }
  }, [location.search]);

  return (
    <ScreenFrame
      title={customText.title || defaultTitle}
      titleIconComponent={
        <IconComponent
          width={25}
          height={25}
          style={{ color: primaryColor }}
        />
      }
      color={primaryColor}
      secondaryColor={secondaryColor}
      hasBackButton
    >
      <div className={Style.content_container}>
        <div className={Style.detail_card}>
          <DetailsCard
            editEnabled={editEnabled}
            detailsCardData={detailsCardData}
          />
        </div>
        <div className={Style.details_page_tabs}>
          <DetailsPageTabs />
        </div>
      </div>
    </ScreenFrame>
  );
};

export const DetailsPage = (props: DetailsPageProps) => {
  const { featureMap, onQueryDetails } = props;
  return (
    <DetailsPageStateContextProvider
      solutionFeatureMap={featureMap}
      onQueryDetails={onQueryDetails}
    >
      <DetailsPageFeature {...props} />
    </DetailsPageStateContextProvider>
  );
};
