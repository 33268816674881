import { TRANSFER_STATUS_PILL_MAP } from "../../../../../kiosk/features/selected-kiosk-feature/features/transfer-order-actions/data/constants";
import type { TransferOrderItem } from "../../../../contants/types";
import { TransferItemsEntry } from "../../data/types";

export const parseItemEntryItems = (itemsEntry: TransferItemsEntry): TransferOrderItem[] => {
  const { hasTransferItemState } = itemsEntry;
  return hasTransferItemState.map((transferItemState) => {
    const {
      ofItem: [item],
      transfer_status: transferStatus
    } = transferItemState;
    const {
      hasSensorProfile: [sensorProfile],
      isContainedIn: [container]
    } = item || { hasSensorProfile: [], isContainedIn: [] };

    const containerFields = Object.entries(container || {}).reduce((acc: Record<string, unknown>, [key, value]) => {
      acc[`container_${key}`] = value;
      return acc;
    }, {});
    const status = TRANSFER_STATUS_PILL_MAP[transferStatus];

    return {
      ...transferItemState,
      ...item,
      ...containerFields,
      vid: sensorProfile?.vid,
      status: status ? [status] : [],
      statusSortValue: status?.sortIndex
    };
  });
};
