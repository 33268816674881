import React, { PureComponent } from "react";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardBody } from "mdbreact";
import TextareaAutosize from "react-textarea-autosize";
import "../../pages/order/TrackOrderPage.css";
import { getCustomerLogo, getStackedXemelgoLogo } from "common/Utilities";
import Skeleton from "react-loading-skeleton";
import ImagesDisplayAndUpload from "../images-display-and-upload";
import Style from "./css/DetailCard.module.css";
import DetailsCardDisplayedFields from "./components/details-card-displayed-fields";

const MAX_NUMBER_OF_IMAGES = 20;

export default class DetailCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      imageFilesToUpload: [],
      imagePathsToRemove: []
    };
    this.handleEditSave.bind(this);
  }

  async componentDidMount() {
    const { titleStructure = {}, detailStructureList = {}, remarks = "" } = this.props;
    const state = {
      remarks,
      remarksDisabled: false
    };
    state.customerLogo = (await getCustomerLogo()) || getStackedXemelgoLogo("dark");
    if (titleStructure.editable) {
      state[titleStructure.key] = titleStructure.value;
    }
    detailStructureList.forEach((each) => {
      if (each.editable) {
        state[each.key] = each.value;
      }
    });
    this.setState(state);
  }

  handleChange = (key, value, type) => {
    this.setState({
      [key]: type === "number" ? parseFloat(value ? value.replace(/\D/g, "") : 0) : value
    });
  };

  handleRemarksChange = (event) => {
    this.setState({
      remarks: event.target.value
    });
  };

  handleRemarksCancel = () => {
    const { remarks } = this.props;
    this.setState({
      remarks
    });
  };

  handleRemarksSave = async () => {
    const { onRemarksSubmit } = this.props;
    const { remarks } = this.state;
    this.setState({
      remarksDisabled: true
    });
    await onRemarksSubmit(remarks);
    this.setState({
      remarksDisabled: false
    });
  };

  handleEditCancel = () => {
    const { titleStructure = {}, detailStructureList = {} } = this.props;
    const state = { isEditing: false, imageFilesToUpload: [], imagePathsToRemove: [] };
    if (titleStructure.editable) {
      state[titleStructure.key] = titleStructure.value;
    }
    detailStructureList.forEach((each) => {
      if (each.editable) {
        state[each.key] = each.value;
      }
    });

    this.setState(state);
  };

  handleEditSave = () => {
    const { titleStructure, detailStructureList, onSubmit, onSubmitImages = () => {} } = this.props;
    const { isEditing, imageFilesToUpload, imagePathsToRemove } = this.state;

    if (isEditing) {
      // Determine which fields were changed and put them in formData
      const formData = {};
      Object.keys(this.state).forEach((eachKey) => {
        let changed = false;

        if (eachKey !== "isEditing") {
          const detailItem = detailStructureList.find((each) => {
            return each.key === eachKey;
          });
          if (eachKey === titleStructure.key && this.state[eachKey] !== titleStructure.value) {
            changed = true;
          } else if (detailItem && this.state[eachKey] !== detailItem.value) {
            changed = true;
          }
        }
        if (changed) {
          formData[eachKey] = this.state[eachKey];
        }
      });
      onSubmit(formData);

      if (imageFilesToUpload.length || imagePathsToRemove.length) {
        onSubmitImages(
          imageFilesToUpload.map((image) => {
            return image.file;
          }),
          [...imagePathsToRemove]
        );
      }
    }

    this.setState({ isEditing: false, imageFilesToUpload: [], imagePathsToRemove: [] });
  };

  renderMoreButton = () => {
    const { getAdditionalMoreButtonOptions, editButtonLabel } = this.props;
    return (
      <Dropdown>
        <DropdownToggle
          nav
          className={Style.dropdown_toggle}
          data-cy-detail-card__more-button
        >
          <MoreIcon />
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem
            className="dropdown-item"
            onClick={() => {
              this.setState({ isEditing: true });
            }}
          >
            {editButtonLabel || "Edit Details"}
          </DropdownItem>
          {getAdditionalMoreButtonOptions && getAdditionalMoreButtonOptions()}
        </DropdownMenu>
      </Dropdown>
    );
  };

  renderEditActionButtons = () => {
    return (
      <div className={Style.edit_form_buttons}>
        <button
          type="button"
          className={`${Style.btn} ${Style.gray_btn}`}
          onClick={this.handleEditCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className={`${Style.btn} ${Style.blue_btn}`}
          onClick={this.handleEditSave}
        >
          Save
        </button>
      </div>
    );
  };

  renderDetailGroups = (detailStructureList, isActive) => {
    const { isEditing } = this.state;
    const { isLoading, onExpeditedStatusChange = () => {} } = this.props;
    if (isLoading) {
      return <Skeleton />;
    }

    return (
      <DetailsCardDisplayedFields
        details={this.state}
        fields={detailStructureList}
        onStateChange={this.handleChange}
        onExpeditedStatusChange={onExpeditedStatusChange}
        isEditing={isEditing}
        isActive={isActive}
      />
    );
  };

  renderStatus = (statusList) => {
    return statusList
      .filter((a, b) => {
        return statusList.indexOf(a) === b;
      })
      .map((eachStatus) => {
        return (
          <div
            key={eachStatus.displayText}
            style={{ backgroundColor: eachStatus.color }}
            className={Style.status}
          >
            <p
              className={Style.status_text}
              style={{ color: eachStatus.textColor }}
              data-cy-work-order-track-page-detail-card__status
            >
              {eachStatus.displayText}
            </p>
          </div>
        );
      });
  };

  renderRemarks = () => {
    const { remarks: propsRemarks = "" } = this.props;
    const { remarks, remarksDisabled } = this.state;

    const remarksHasChanges = propsRemarks !== remarks;
    return (
      <>
        <div className={Style.remarks_container}>
          <div className={Style.remarks_title}>Remarks:</div>
          <textarea
            disabled={remarksDisabled}
            value={remarks || ""}
            className={Style.remarks_text}
            onChange={this.handleRemarksChange}
            rows={6}
          />
        </div>
        {remarksHasChanges && !remarksDisabled && (
          <div className={Style.remarks_button_container}>
            <button
              className={`${Style.btn} ${Style.remarks_cancel_button}`}
              onClick={this.handleRemarksCancel}
            >
              Cancel
            </button>
            <button
              className={`${Style.btn} ${Style.remarks_submit_button}`}
              onClick={this.handleRemarksSave}
            >
              Submit
            </button>
          </div>
        )}
      </>
    );
  };

  componentWillReceiveProps = (props) => {
    const { detailStructureList = {} } = props;
    detailStructureList.forEach((each) => {
      if (each.editable) {
        this.setState({ [each.key]: each.value });
      }
    });
  };

  addItemImages = (images) => {
    this.setState({ imageFilesToUpload: images });
  };

  removeItemImage = (imagePath) => {
    this.setState({ imagePathsToRemove: [...this.state.imagePathsToRemove, imagePath] });
  };

  render() {
    const {
      defaultImage = "",
      images = [],
      titleStructure = {},
      detailStructureList = [],
      statusList = [],
      isActive = true,
      remarksEnabled = false,
      editEnabled = true,
      isLoading
    } = this.props;
    const { isEditing, customerLogo } = this.state;
    const { key, editable, name, value } = titleStructure;

    const itemImages = images
      .filter((image) => {
        return !this.state.imagePathsToRemove.includes(image);
      })
      .sort((a, b) => {
        return a.localeCompare(b);
      });
    const imageEditEnabled = isEditing && !!images;
    if (!isEditing || !imageEditEnabled) {
      if (defaultImage) {
        itemImages.unshift(defaultImage);
      } else if (itemImages.length === 0) {
        itemImages.unshift(customerLogo);
      }
    }

    return (
      <Card
        className={Style.detail_card}
        data-cy="detail-card"
      >
        <CardBody className={Style.detail_card_body}>
          <ImagesDisplayAndUpload
            images={itemImages}
            isEditMode={imageEditEnabled}
            onImagesAdd={this.addItemImages}
            onImageRemove={this.removeItemImage}
            maxNumberOfFiles={MAX_NUMBER_OF_IMAGES}
            isLoading={isLoading}
          />
          {statusList.length > 0 && <div className={Style.status_group}>{this.renderStatus(statusList)}</div>}
          <div className={Style.top_group}>
            <div className={Style.title_group}>
              {isLoading ? (
                <Skeleton width={200} />
              ) : (
                <>
                  <div className={Style.title_label}>{`${name || "Name"}:`}</div>
                  {editable && isEditing ? (
                    <TextareaAutosize
                      id={key}
                      className={Style.title_value_input}
                      value={this.state[key]}
                      onChange={({ currentTarget }) => {
                        this.handleChange(key, currentTarget.value);
                      }}
                      data-cy-detail-card__title
                    />
                  ) : (
                    <div className={Style.title_value}>{value || ""}</div>
                  )}
                </>
              )}
            </div>
            {editEnabled && !isEditing && isActive && !isLoading && <this.renderMoreButton />}
          </div>
          <div className={Style.bottom_group}>{this.renderDetailGroups(detailStructureList, isActive)}</div>
          {isEditing && <this.renderEditActionButtons />}
          {remarksEnabled && this.renderRemarks()}
        </CardBody>
      </Card>
    );
  }
}
