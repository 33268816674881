import { XemelgoService } from "../../../../../../../../../../services/XemelgoService";

export const publishDetectorEvents = async (transferOrder, completeItemTypeReports, submitStatus, detectorId) => {
  const publishClient = XemelgoService.getClient().getPublishClient();

  const itemTypeToReport = completeItemTypeReports.reduce((acc, report) => {
    acc[report.itemTypeId] = report;
    return acc;
  }, {});

  const itemTypeEvents = transferOrder.hasItemsEntry.reduce((acc, itemsEntry) => {
    const { id: itemsEntryId, ofItemType = [] } = itemsEntry;

    const itemTypeId = ofItemType[0]?.id;

    const report = itemTypeToReport[itemTypeId];

    if (report) {
      const epcToItemMap = report.epcToItemMap || {};
      const items = Object.values(epcToItemMap);

      const sensorProfileIds = items.map((item) => {
        return item.sensorProfileId;
      });

      acc.push({
        itemsEntryId,
        sensorProfileIds
      });
    }

    return acc;
  }, []);

  await Promise.all(
    itemTypeEvents.map(async (itemTypeEvent) => {
      const { sensorProfileIds, itemsEntryId } = itemTypeEvent;

      const context = {
        actions: {
          updateTransferOrder: {
            transfer_order_id: transferOrder.id,
            transfer_item_entry_id: itemsEntryId,
            transfer_status: submitStatus
          }
        }
      };

      return publishClient.publishDetectorEventInBatches(detectorId, sensorProfileIds, context);
    })
  );
};
