import React, { useRef, useState, useEffect } from "react";
import ScreenFrame from "components/screen-frame";
import * as turf from "@turf/turf";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import "./InventoryDashboardFeature.css";
import MarkerImage from "../../img/map-marker.png";
import DashboardIcon from "../../img/inventory-dashboard_icon_blue.png";

const geojson = [
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-74.00209, 40.758323]
    },
    properties: {
      id: "NY"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-122.413682, 37.775408]
    },
    properties: {
      id: "CA"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-122.23395217995576, 47.38477548550431]
    },
    properties: {
      id: "WA"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-122.7436859293746, 45.365909440014526]
    },
    properties: {
      id: "OR"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-116.86338713105704, 32.46496582845944]
    },
    properties: {
      id: "NA"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-104.89901933913708, 39.63833057191181]
    },
    properties: {
      id: "CO"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-93.4039670820809, 44.92141979995973]
    },
    properties: {
      id: "MN"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-87.87645758332054, 41.84821095906443]
    },
    properties: {
      id: "MI"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-90.18497836956168, 38.60145866427806]
    },
    properties: {
      id: "ID"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-96.864975987123, 32.76004968851743]
    },
    properties: {
      id: "TX"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-95.37490738363007, 29.74993999212807]
    },
    properties: {
      id: "TX2"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-81.48521055033805, 28.553211353784608]
    },
    properties: {
      id: "FL"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-80.36794405335723, 25.96950701747255]
    },
    properties: {
      id: "FL2"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-84.35238033557188, 33.74545315956807]
    },
    properties: {
      id: "GA"
    }
  }
];

mapboxgl.accessToken = "pk.eyJ1IjoieGVtZWxnbyIsImEiOiJjbDNvdjZ4ZnEwNng1M2NwbXR1OWJ2aWNiIn0.isF9cAXWgzw0yLEJ6U15bA";

class ClickableMarker extends mapboxgl.Marker {
  // new method onClick, sets _handleClick to a function you pass in
  onClick(handleClick) {
    this._handleClick = handleClick;
    return this;
  }

  // the existing _onMapClick was there to trigger a popup
  // but we are hijacking it to run a function we define
  _onMapClick(e) {
    const targetElement = e.originalEvent.target;
    const element = this._element;

    if (this._handleClick && (targetElement === element || element.contains(targetElement))) {
      this._handleClick();
    }
  }
}

export default ({ history }) => {
  const mapRef = useRef(null);
  const mapContainerComponentRef = useRef(null);
  const [map, setMap] = useState(null);
  const [markerList, setMarkerList] = useState([]);

  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerComponentRef.current,
      style: "mapbox://styles/xemelgo/cl4hcfnf7000014nvc9tawvs3",
      maxPitch: 0,
      zoom: 1,
      maxBounds: [
        [-135.926, 18.92], // Southwest
        [-55.78, 54.013516] // Northeast
      ],
      center: turf.center(
        turf.points([
          [-135.926, 18.92], // Southwest
          [-55.78, 54.013516] // Northeast
        ])
      ).geometry.coordinates
    });
    return () => {
      mapRef.current.remove();
    };
  }, []);

  useEffect(() => {
    setMap(mapRef.current);
  }, [mapRef.current]);

  useEffect(() => {
    if (map) {
      map.on("load", () => {
        map.resize();
      });
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      markerList.forEach((eachMarker) => {
        eachMarker.remove();
      });
      const newMarkerList = [];
      geojson.forEach((eachFeature) => {
        const el = document.createElement("img");
        el.style.width = "60px";
        el.style.paddingBottom = "50px";
        el.style.objectFit = "contain";
        el.src = MarkerImage;
        el.onmouseenter = () => {
          el.style.width = "70px";
          el.style.paddingBottom = "60px";
        };
        el.onmouseleave = () => {
          el.style.width = "60px";
          el.style.paddingBottom = "50px";
        };
        newMarkerList.push(
          new ClickableMarker(el)
            .setLngLat(eachFeature.geometry.coordinates)
            .onClick(() => {
              if (eachFeature.properties.id === "NY") {
                history.push("/inventory");
              }
            })
            .addTo(map)
        );
      });
      setMarkerList(newMarkerList);
    }
  }, [map]);

  return (
    <ScreenFrame
      color="#297AD9"
      title="Inventory"
      titleIconComponent={
        <img
          src={DashboardIcon}
          width="45px"
          height="45px"
          style={{ paddingTop: 1, paddingRight: 1 }}
        />
      }
    >
      <div
        ref={mapContainerComponentRef}
        style={{ width: "100%", height: "100%" }}
      />
    </ScreenFrame>
  );
};
