import React from "react";
import { DEFAULT_ITEM_ENTRY_TABLE_HEADERS, ITEM_CLASS_TO_URL_PATH } from "../../data/constants";
import PaginatedListTableWithTypes, { Header } from "../../../../../../components/paginated-list-table-with-types";
import { TransferOrderItem } from "../../../../contants/types";

interface TransferItemsEntryTableProps {
  headers?: Header[];
  items: TransferOrderItem[];
}

export const TransferItemsEntryTable = ({ headers, items }: TransferItemsEntryTableProps) => {
  return (
    <PaginatedListTableWithTypes
      dataList={items}
      headers={headers || DEFAULT_ITEM_ENTRY_TABLE_HEADERS}
      paginatorLocation="top"
      getTitleURLCallbackFn={(item: any, headerId: string) => {
        if (headerId.includes("container")) {
          return item.container_id
            ? `/${ITEM_CLASS_TO_URL_PATH.Container}/item/detail?itemId=${item.container_id}`
            : "";
        }

        const path = ITEM_CLASS_TO_URL_PATH[item.class];
        if (path && item.id) {
          return `/${path}/item/detail?itemId=${item.id}`;
        }

        return "";
      }}
      sortIdReplacementMap={{
        status: "statusSortValue"
      }}
      numItemsPerPage={5}
    />
  );
};
