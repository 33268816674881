import React from "react";
import PropTypes from "prop-types";
import PrintTagsModal from "../../../print-tags-modal";
import MarkAsCompletedActionModal from "../../../mark-as-completed-action-modal";

export const MultiSelectActionModal = ({ action, selectedItems, onModalClose }) => {
  switch (action.id) {
    case "printTags":
      return (
        <PrintTagsModal
          onCloseModal={onModalClose}
          selectedItems={selectedItems}
          printTagsOptions={action}
          appId="asset"
        />
      );
    case "markAsComplete":
      return (
        <MarkAsCompletedActionModal
          title={action.modalTitle || "Archive Asset"}
          body={action.modalBody || 'Archived assets will no longer be tracked. Press "Confirm" to proceed.'}
          onCloseModal={() => {
            onModalClose(action.id, false, "");
          }}
          itemIds={selectedItems.map(({ id }) => {
            return id;
          })}
          onPostConfirm={async (error) => {
            onModalClose(
              action.id,
              !error,
              !error
                ? action.successMessage ||
                    "Successfully archived the selected assets. The data will be updated momentarily."
                : action.failureMessage ||
                    "Failed to archive the selected asset(s). Please try again later or contact Xemelgo for support."
            );
          }}
          detachSensorProfiles={action.detachSensorProfiles}
        />
      );
    default:
      return null;
  }
};

MultiSelectActionModal.defaultProps = {
  onModalClose: () => {}
};

MultiSelectActionModal.propTypes = {
  action: PropTypes.shape({ id: PropTypes.string, label: PropTypes.string }).isRequired,
  selectedItems: PropTypes.array.isRequired,
  onModalClose: PropTypes.func
};
