import React, { useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import Style from "./DetailsPageTabs.module.css";
import useDetailsPageConfigContext from "../../contexts/details-page-config-context";
import TabBar from "../../../../components/tab-bar";
import useDetailsPageStateContext from "../../contexts/details-page-state-context";

export const DetailsPageTabs = () => {
  const { tabs } = useDetailsPageConfigContext();
  const { featureMap, isLoading } = useDetailsPageStateContext();

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const currentTab = useMemo(() => {
    return tabs[currentTabIndex];
  }, [currentTabIndex, tabs]);

  return (
    <div>
      {tabs.length > 1 && currentTab.label && (
        <TabBar
          containerClassName={Style.tab_bar}
          tabList={tabs.map((tab) => {
            return {
              id: tab.label,
              label: tab.label
            };
          })}
          currentTabId={currentTab.label}
          onTabClick={(_, index) => {
            setCurrentTabIndex(index);
          }}
        />
      )}
      <div
        key={currentTab?.label || "details-subfeatures"}
        className={Style.tab_content}
      >
        {isLoading ? (
          <div>
            <Skeleton
              className={Style.title_text}
              width={120}
              height={40}
            />
            <Skeleton className={Style.skeleton_table} />
          </div>
        ) : (
          currentTab.features.map((feature) => {
            const { id, title } = feature;
            const subfeature = featureMap[id];

            if (!subfeature) {
              return null;
            }

            return (
              <div
                key={id}
                data-cy={`details-page-${id}`}
              >
                {title && <p className={Style.title_text}>{title}</p>}
                {subfeature}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
