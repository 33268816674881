import AddButton from "../features/add-button";
import BulkUpdateButton from "../features/bulk-update-button";

export const FEATURE_BUTTON_ID_MAP = {
  addItem: "add-item",
  bulkUpdate: "bulk-update"
};

export const FEATURE_BUTTON_COMPONENT_MAP = {
  [FEATURE_BUTTON_ID_MAP.addItem]: AddButton,
  [FEATURE_BUTTON_ID_MAP.bulkUpdate]: BulkUpdateButton
};
