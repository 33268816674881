import { XemelgoService } from "../../../../../../../../../../services/XemelgoService";

export const createTransferOrder = async (transferOrderIdentifier, completeItemTypeReports, itemClass) => {
  const transferClient = XemelgoService.getClient().getTransferClient();

  const itemEntries = completeItemTypeReports.reduce((acc, itemTypeReport) => {
    const { itemTypeId, epcToItemMap } = itemTypeReport;
    const items = Object.values(epcToItemMap);

    if (itemTypeId && items.length) {
      acc.push({
        fields: {
          aggregation_method: "Manual",
          quantity_total: items.length
        },
        connections: {
          ofItemType: itemTypeId,
          hasTransferItemState: items.map((item) => {
            return {
              fields: {
                transfer_status: "Added"
              },
              connections: {
                ofItem: item.itemId
              }
            };
          })
        }
      });
    }

    return acc;
  }, []);

  const response = await transferClient.createTransferOrder(
    transferOrderIdentifier,
    "Created",
    undefined,
    undefined,
    itemEntries,
    itemClass
  );

  return (await transferClient.getTransferOrdersByIds([response.createTransferOrder.id]))[0];
};
