import React, { useCallback, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { ChevronLeft, ChevronRight, ReplayOutlined } from "@material-ui/icons";
import { ReactComponent as SwitchSiteIcon } from "../../assets/icons/switch-site.svg";
import FloorMap from "../../components/FloorMap/FloorMap";
import ScreenFrame from "../../components/screen-frame";
import { useXemelgoClient } from "../../services/xemelgo-service";
import Style from "./AssetMap.module.css";
import { ASSET_MAP_EVENT, ASSET_MAP_STEPS } from "../../constants/mixpanel-constant/assetMap";
import useMixpanelContext from "../../context/mixpanel-context";
import { getFormattedDate } from "../../common/Utilities";
import { useXemelgoAppsyncClient } from "../../services/xemelgo-appsync-service";
import { MAP_REFRESH_INTERVAL, STATUS_COLOR_MAP, TILE_TITLE_MAP } from "./data/constants";
import useAssetMapConfigContext, { AssetMapConfigContextProvider } from "./contexts/asset-map-config-context";
import SearchResultContent from "./features/search-result-content";
import useAssetMapStateContext, { AssetMapStateContextProvider } from "./contexts/asset-map-state-context";
import SelectedLocationContent from "./features/selected-location-content";
import SiteSelectionContent from "./features/site-selection-content";
import { getLocationStatus } from "./utils/get-location-status";
import xemelgoStyle from "../../styles/variable";
import PopupFilter from "./features/popup-filter";
import { aggregateAssetTypeCounts } from "./utils/aggregate-asset-type-counts/aggregateAssetTypeCounts";
import AssetSearch from "./features/asset-search";

const AssetMap = () => {
  const xemelgoClient = useXemelgoClient();
  const xemelgoClientAppSync = useXemelgoAppsyncClient();
  const { sendMixPanelEvent } = useMixpanelContext();

  const [assetClientAppSync] = useState(xemelgoClientAppSync.getAssetClient());

  const {
    locationCategories,
    mapConfig,
    switchSiteControl,
    showAssetStatus,
    isLoading: isConfigLoading
  } = useAssetMapConfigContext();
  const {
    mapRef,
    setSelectedLocationId,
    searchAssetResults,
    locationMap,
    setLocationMap,
    siteViewState,
    siteLocationId,
    setSiteLocation,
    selectedAssetTypeSet,
    locationToCounts,
    showAssetResults,
    setShowAssetResults,
    setSearchString
  } = useAssetMapStateContext();

  const [isLoading, setIsLoading] = useState(true);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(Date.now());
  const [isInformationHidden, setIsInformationHidden] = useState(false);

  useEffect(() => {
    if (!isConfigLoading) {
      sendMixPanelEvent(ASSET_MAP_EVENT, ASSET_MAP_STEPS.ENTRY);
      fetchLocationMapWithMetrics();
      const autoRefreshInterval = setInterval(() => {
        fetchLocationMapWithMetrics();
      }, MAP_REFRESH_INTERVAL);

      return () => {
        clearInterval(autoRefreshInterval);
      };
    }
  }, [isConfigLoading]);

  const colorMap = useMemo(() => {
    let locationStatuses = [];

    if (searchAssetResults.length) {
      locationStatuses = searchAssetResults.map((location) => {
        return {
          locationId: location.id,
          status: showAssetStatus ? getLocationStatus(location) : "TrackedLocation"
        };
      });
    } else if (!selectedAssetTypeSet.size) {
      locationStatuses = Object.values(locationMap).map((location) => {
        return {
          locationId: location.id,
          status: showAssetStatus ? location.status : "TrackedLocation"
        };
      });
    } else {
      locationStatuses = Object.values(locationMap).reduce((acc, location) => {
        let status = showAssetStatus ? location.status : "TrackedLocation";
        const aggregateLocationTypeCounts = locationToCounts[location.id];

        if (aggregateLocationTypeCounts.totalCount === 0) {
          return acc;
        }
        if (showAssetStatus && aggregateLocationTypeCounts.expiredCount > 0) {
          status = "Critical";
        } else if (showAssetStatus && aggregateLocationTypeCounts.expiringSoonCount > 0) {
          status = "Warning";
        }

        acc.push({
          locationId: location.id,
          status
        });
        return acc;
      }, []);
    }

    const newColorMap = showAssetStatus
      ? {
          [STATUS_COLOR_MAP.Healthy]: {
            label: "Healthy",
            locations: ["defaultHealthyLocation"]
          },
          [STATUS_COLOR_MAP.Warning]: {
            label: "Warning",
            locations: ["defaultWarningLocation"]
          },
          [STATUS_COLOR_MAP.Critical]: {
            label: "Critical",
            locations: ["defaultCriticalLocation"]
          },
          [STATUS_COLOR_MAP.EmptyLocation]: {
            label: "Empty Location",
            locations: ["defaultEmptyLocation"]
          }
        }
      : {
          [STATUS_COLOR_MAP.TrackedLocation]: {
            label: "Tracked Location",
            locations: ["defaultTrackedLocation"]
          }
        };

    locationStatuses.forEach((eachLocation) => {
      newColorMap[STATUS_COLOR_MAP[eachLocation.status]].locations.push(eachLocation.locationId);
    });

    return newColorMap;
  }, [searchAssetResults, locationMap, showAssetStatus, selectedAssetTypeSet, locationToCounts]);

  const fetchLocationMapWithMetrics = async () => {
    const locationClient = xemelgoClient.getLocationClient();

    const newLocationMap = await locationClient.getLocationTree(
      [],
      !locationCategories?.length || locationCategories.includes("all") ? null : locationCategories
    );
    const locationIds = Object.keys(newLocationMap);

    const [assetMetricsByLocation, assetTypeMetricsByLocation] = await Promise.all([
      assetClientAppSync.getMetricsByLocationIds("items", locationIds),
      assetClientAppSync.getMetricsByLocationIds("itemType", locationIds)
    ]);

    const aggregatedAssetTypeMetrics = aggregateAssetTypeCounts(newLocationMap, assetTypeMetricsByLocation);

    locationIds.forEach((eachLocationId) => {
      const assetCounts = assetMetricsByLocation[eachLocationId].counts;

      newLocationMap[eachLocationId].assetCounts = assetCounts;
      newLocationMap[eachLocationId].assetTypeMap = aggregatedAssetTypeMetrics[eachLocationId] || {};

      if (assetCounts.totalCount === 0) {
        newLocationMap[eachLocationId].status = "EmptyLocation";
      } else if (assetCounts.expiredCount > 0) {
        newLocationMap[eachLocationId].status = "Critical";
      } else if (assetCounts.expiringSoonCount > 0) {
        newLocationMap[eachLocationId].status = "Warning";
      } else {
        newLocationMap[eachLocationId].status = "Healthy";
      }
    });

    setLastUpdatedTime(Date.now());
    setLocationMap(newLocationMap);
    sendMixPanelEvent(ASSET_MAP_EVENT, ASSET_MAP_STEPS.DATA_LOADED);
    setIsLoading(false);
  };

  const onLocationClicked = (id, data) => {
    const locationId = id || siteLocationId;
    setSelectedLocationId(locationId);

    const { properties, center } = data || {};
    if (!siteLocationId) {
      setSiteLocation(id, {
        ...JSON.parse(properties?.viewStates || "{}"),
        zoom: properties?.zoom,
        center
      });
    }
  };

  const TitleRightComponent = () => {
    return (
      <div className={`${Style.flex_row} ${Style.title_right_container}`}>
        {switchSiteControl?.enabled && (
          <div
            className={`${Style.title_right_reload_button} ${Style.flex_column}`}
            onClick={() => {
              setSelectedLocationId(null);
              setSiteLocation(null, {});
              setShowAssetResults(false);
              setSearchString("");

              mapRef.current.resetMap({
                shouldTriggerCallback: false,
                viewState: mapConfig.initialViewStates || {}
              });
            }}
          >
            <SwitchSiteIcon
              width={30}
              height={30}
            />
            <p>{`Select ${(switchSiteControl?.locationCategory || "site").toLowerCase()}`}</p>
          </div>
        )}
        <div
          className={`${Style.title_right_reload_button} ${Style.flex_column}`}
          onClick={fetchLocationMapWithMetrics}
        >
          <ReplayOutlined />
          <p>Reload</p>
        </div>
        <div>
          <p>Last updated:</p>
          <p>{lastUpdatedTime ? getFormattedDate(lastUpdatedTime) : "-"}</p>
        </div>
      </div>
    );
  };

  const popUp = useCallback(
    (locationId) => {
      const locationCounts = locationToCounts[locationId];
      const timeExceededMetricFields = ["expiredCount", "expiringSoonCount"];

      if (!locationCounts) {
        return null;
      }

      return (
        <>
          <div className={Style.popup_title_container}>{locationMap[locationId]?.name}</div>

          <div className={Style.popup_content_container}>
            <div className={Style.popup_label}>
              Total Assets:
              <p className={Style.popup_value}>{locationCounts.totalCount.toLocaleString()}</p>
            </div>

            {showAssetStatus &&
              timeExceededMetricFields.map((eachMetric) => {
                return (
                  <div key={eachMetric}>
                    {`${TILE_TITLE_MAP[eachMetric]}: `}
                    <p
                      className={Style.popup_value}
                      style={{
                        color: STATUS_COLOR_MAP[eachMetric]
                      }}
                    >
                      {locationCounts[eachMetric].toLocaleString()}
                    </p>
                  </div>
                );
              })}
          </div>
        </>
      );
    },
    [locationToCounts, locationMap]
  );

  return (
    <ScreenFrame
      verticalExpandDisabled
      title="Dashboard"
      color={xemelgoStyle.theme.ASSET_PRIMARY}
      secondaryColor={xemelgoStyle.theme.ASSET_SECONDARY}
      titleIconComponent={() => {
        return (
          <img
            alt="asset-map-icon"
            src={require("../../img/asset-map_icon_red.png")}
            width="40x"
            height="40px"
          />
        );
      }}
      titleRightComponent={<TitleRightComponent />}
    >
      {!isLoading ? (
        <FloorMap
          ref={mapRef}
          mapStyleURL={mapConfig.mapStyleURL || ""}
          floorPlanTilesets={mapConfig.floorPlanTilesets || []}
          locationsSourceTilesets={mapConfig.locationsSourceTilesets || []}
          markersGeojson={mapConfig?.markersGeojson || null}
          initialViewStates={
            !switchSiteControl?.enabled || !siteLocationId
              ? mapConfig.initialViewStates || {}
              : siteViewState || mapConfig.initialViewStates || {}
          }
          colorMap={colorMap}
          showColorIndicator
          mapCameraPadding={!isInformationHidden && { right: 550 }}
          mapNavigationControlClassName={`${Style.map_zoom_control} ${
            isInformationHidden && Style.map_zoom_control_collapsed
          }`}
          mapColorIndicatorClassName={Style.map_color_indicator}
          popupEnabled
          popupRenderer={(locationId) => {
            return popUp(locationId);
          }}
          onLocationClicked={onLocationClicked}
        >
          <div className={`${Style.flex_row} ${Style.top_left_action_container}`}>
            <AssetSearch />
          </div>
          <div
            className={`${Style.flex_column} ${Style.top_right_action_container} ${
              isInformationHidden && Style.top_right_action_container_collapsed
            }`}
          >
            <div className={`${Style.top_right_action}`}>
              <PopupFilter />
            </div>
          </div>
          <div
            className={`${Style.flex_row} ${Style.information_collapsible_button} ${
              isInformationHidden && Style.information_collapsible_button_collapsed
            }`}
            onClick={() => {
              setIsInformationHidden((currentValue) => {
                return !currentValue;
              });
            }}
          >
            {isInformationHidden ? <ChevronLeft /> : <ChevronRight />}
          </div>
          <div
            className={`${Style.flex_column} ${Style.information_container} ${
              isInformationHidden && Style.information_container_collapsed
            }`}
          >
            {showAssetResults ? (
              <SearchResultContent />
            ) : switchSiteControl?.enabled && !siteLocationId ? (
              <SiteSelectionContent />
            ) : (
              <SelectedLocationContent />
            )}
          </div>
        </FloorMap>
      ) : (
        <Skeleton className={Style.map_skeleton} />
      )}
    </ScreenFrame>
  );
};

export default () => {
  return (
    <AssetMapConfigContextProvider>
      <AssetMapStateContextProvider>
        <AssetMap />
      </AssetMapStateContextProvider>
    </AssetMapConfigContextProvider>
  );
};
