import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import useKioskConfigContext from "../../../../../../../../contexts/kiosk-config-context";
import useKioskStateContext from "../../../../../../../../contexts/kiosk-state-context";
import { STATUS_OPTIONS } from "../../../../../../../../../../components/status-popup-component/data/constants";
import { KIOSK, KIOSK_STEPS } from "../../../../../../../../../../constants/mixpanel-constant/kiosk";
import useMixpanelContext from "../../../../../../../../../../context/mixpanel-context";
import usePrintService from "../../../../../../../../../../hooks/use-print-service";
import ContainerAssociationModal from "../../../container-association-modal";
import SubmissionConfirmationModal from "./features/submission-confirmation-modal";
import useTransferOrderActionsConfigContext from "../../../../contexts/transfer-order-actions-config-context";
import submitTransferOrderAction from "../../../../utils/submit-transfer-order-action";
import { TRANSFER_ORDER_STAGES } from "../../../../data/constants";
import useKioskSearchParams from "../../../../../../../../hooks/use-kiosk-search-params";

export const OrderCreationSubmissionWorkflow = ({
  existingTransferOrder,
  newTransferOrderIdentifier,
  existingContainer,
  newContainer,
  onClose,
  stage
}) => {
  const { setActionId } = useKioskSearchParams();
  const { sendMixPanelEvent } = useMixpanelContext();
  const { setSubmitStatus, setStatusMessage, panelValues, itemTypeReports } = useKioskStateContext();
  const { action, printOptions, skipSubmission } = useKioskConfigContext();
  const { containerAssociationOptions, defaultStageId } = useTransferOrderActionsConfigContext();

  const [showContainerAssociation, setShowContainerAssociation] = useState(containerAssociationOptions.enabled);

  const printService = usePrintService(printOptions.enabled, printOptions.printType, printOptions.printConfig, "kiosk");
  const { printItems, printTime } = printService;

  const numItems = useMemo(() => {
    return itemTypeReports.reduce((acc, curr) => {
      return acc + curr.quantityScanned;
    }, 0);
  }, [itemTypeReports]);

  const onConfirm = async () => {
    setSubmitStatus(STATUS_OPTIONS.LOADING);
    setStatusMessage(`Submitting ${action.label}`);

    const detectorId = panelValues.readerLocation?.detectorId;

    try {
      const params = {
        existingTransferOrders: existingTransferOrder ? [existingTransferOrder] : [],
        newTransferOrderIdentifier,
        existingContainer,
        newContainer,
        stage,
        itemTypeReports,
        detectorId,
        printOptions,
        printTime,
        printItems,
        actionType: action.actionType,
        containerTypeIdentifier: containerAssociationOptions.containerTypeIdentifier
      };

      if (!skipSubmission) {
        await submitTransferOrderAction(params);
      }

      setSubmitStatus(STATUS_OPTIONS.SUCCESS);
      setStatusMessage(`The order has been created.`);
      sendMixPanelEvent(KIOSK, KIOSK_STEPS.SUBMISSION_SUCCESSFUL, { action: action.actionType });
    } catch (e) {
      setSubmitStatus(STATUS_OPTIONS.ERROR);
      setStatusMessage(`Failed to create the order. Please contact Xemelgo Support for assistance.`);
      sendMixPanelEvent(KIOSK, KIOSK_STEPS.SUBMISSION_FAILED, {
        action: action.actionType,
        error: JSON.stringify(e)
      });
    }

    setActionId();
  };

  return showContainerAssociation ? (
    <ContainerAssociationModal
      numItems={numItems}
      printService={printService}
      onConfirm={() => {
        setShowContainerAssociation(false);
      }}
      onCancel={onClose}
      containerIdentifier={existingContainer?.identifier || newContainer?.identifier}
      stageActionLabel={TRANSFER_ORDER_STAGES[defaultStageId]?.actionLabel}
    />
  ) : (
    <SubmissionConfirmationModal
      onCancel={onClose}
      onConfirm={onConfirm}
      itemTypeReports={itemTypeReports}
      containerIdentifier={existingContainer?.identifier || newContainer?.identifier}
      numItems={numItems}
    />
  );
};

OrderCreationSubmissionWorkflow.defaultProps = {
  newTransferOrderIdentifier: "",
  existingTransferOrder: null,
  existingContainer: null,
  newContainer: null
};

OrderCreationSubmissionWorkflow.propTypes = {
  newTransferOrderIdentifier: PropTypes.string,
  existingTransferOrder: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  stage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    statusLabel: PropTypes.string.isRequired,
    submitStatus: PropTypes.string.isRequired
  }).isRequired,
  existingContainer: PropTypes.shape({
    identifier: PropTypes.string
  }),
  newContainer: PropTypes.shape({
    identifier: PropTypes.string,
    vid: PropTypes.string
  })
};
