import { XemelgoService } from "../../../../../../../../../../services/XemelgoService";
import {
  ITEM_SCAN_STATUS_MAP,
  ITEM_TYPE_STATUS_MAP,
  STAGE_TO_SHOWN_ITEM_STATUSES,
  STAGES
} from "../../../../data/constants";

export const createItemTypeToReportMap = async (selectedTransferOrders, additionalQueryAttributes, stageId) => {
  const transferOrderClient = XemelgoService.getClient().getTransferClient();

  const transferOrderIds = new Set();

  selectedTransferOrders.forEach((transferOrder) => {
    transferOrderIds.add(transferOrder.id);

    transferOrder.contains.forEach((childTransferOrder) => {
      transferOrderIds.add(childTransferOrder.id);
    });
  });

  const transferOrders = await transferOrderClient.getTransferOrdersByIds(transferOrderIds, additionalQueryAttributes);

  const itemTypeToReport = transferOrders.reduce((acc, transferOrder) => {
    transferOrder.hasItemsEntry.forEach((itemEntry) => {
      const {
        hasTransferItemState = [],
        ofItemType = [],
        quantity_total: quantityTotal = 0,
        quantity_staged: quantityStaged = 0,
        quantity_in_progress: quantityInProgress = 0,
        quantity_received: quantityReceived = 0,
        quantity_verified: quantityVerified = 0
      } = itemEntry;
      const stageToCounts = {
        [STAGES.STAGE]: quantityStaged + quantityInProgress + quantityReceived + quantityVerified,
        [STAGES.SHIP]: quantityInProgress + quantityReceived + quantityVerified,
        [STAGES.RECEIVE]: quantityReceived + quantityVerified,
        [STAGES.VERIFY]: quantityVerified
      };

      const itemType = ofItemType[0] || {};

      const report = acc[itemType.id] || {
        ...itemType,
        itemTypeId: itemType.id,
        epcs: [],
        quantityTotal: 0,
        quantityScanned: 0,
        status: ITEM_TYPE_STATUS_MAP.notScanned,
        epcToItemMap: {}
      };

      report.quantityTotal += quantityTotal - stageToCounts[stageId];

      hasTransferItemState.forEach((itemState) => {
        const { ofItem: items = [], transfer_status: transferStatus } = itemState;

        if (STAGE_TO_SHOWN_ITEM_STATUSES[stageId].includes(transferStatus)) {
          items.forEach((item) => {
            const { vid, id: sensorProfileId } = item.hasSensorProfile[0];

            report.epcToItemMap[vid] = {
              ...item,
              itemId: item.id,
              sensorProfileId,
              vid,
              scanStatus: ITEM_SCAN_STATUS_MAP.NOT_SCANNED,
              isAssociatedWithOrder: true
            };
          });
        }
      });

      acc[itemType.id] = report;
    });

    return acc;
  }, {});

  const filteredItemTypeToReport = Object.values(itemTypeToReport).reduce((acc, report) => {
    if (report.quantityTotal > 0) {
      acc[report.id] = report;
    }

    return acc;
  }, {});

  return { itemTypeToReport: filteredItemTypeToReport, transferOrders };
};
