import React from "react";
import { VALUE_TYPE_MAP } from "../../data/constants";
import { getFormattedDate } from "../../../../common/utils";
import { msToTime } from "../../../../common/Utilities";
import Status from "../../components/status";
import Comment from "../../components/comment";
import ImageWithText from "../../components/image-with-text";
import type { Header } from "../../data/types";

export const getValueByType = (value: any, header: Header, convertToText?: boolean) => {
  const { type, timeFormat } = header;

  switch (type) {
    case VALUE_TYPE_MAP.boolean:
      return typeof value !== "undefined" ? (value ? "Yes" : "No") : "-";
    case VALUE_TYPE_MAP.dateTime:
      return value ? getFormattedDate(value, timeFormat || "hh:mm A MMM Do") : "-";
    case VALUE_TYPE_MAP.date:
      return value ? getFormattedDate(value, timeFormat || "MMM Do") : "-";
    case VALUE_TYPE_MAP.duration:
      return value !== undefined ? msToTime(value) : "-";
    case VALUE_TYPE_MAP.status:
      if (!Array.isArray(value)) {
        return "-";
      }

      return convertToText ? (
        value?.length ? (
          value
            .map((eachStatus) => {
              return eachStatus.label || eachStatus.title;
            })
            .join(", ")
        ) : (
          "-"
        )
      ) : (
        <Status statusList={value} />
      );
    case VALUE_TYPE_MAP.comment:
      if (convertToText) {
        return value || "-";
      }

      if (typeof value !== "string" || !value) {
        return "-";
      }

      return <Comment comment={value} />;
    case VALUE_TYPE_MAP.number:
      return typeof value === "number" ? value.toLocaleString("us-en") : "0";
    case VALUE_TYPE_MAP.imageWithText:
      const { imageSrc, text } = (value as { imageSrc: string; text: string }) || {};

      if (convertToText) {
        return text || "-";
      }

      const { imageSize = 50 } = header;
      return value ? (
        <ImageWithText
          imageSrc={imageSrc}
          text={text}
          imageSize={imageSize}
        />
      ) : (
        "-"
      );
    case VALUE_TYPE_MAP.text:
    default:
      return (Array.isArray(value) ? value.join(", ") : value) || "-";
  }
};
