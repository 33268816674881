import { SOLUTIONS } from "../../../../../data/constants";
import type { DetailsPageConfigType } from "./types";

const DEFAULT_TRANSFER_ORDER_DETAILS_CONFIG: DetailsPageConfigType = {
  additionalAttributes: {
    item: ["class"],
    itemType: ["image_path", "class"]
  },
  customText: {
    title: "Transfer Order Details",
    subtitle: "Tracking #"
  },
  detailsCard: {
    fields: [
      {
        key: "node_last_updated_time",
        label: "Last Updated",
        editable: false,
        type: "date"
      },
      {
        key: "node_start_time",
        label: "Created",
        editable: false,
        type: "date"
      },
      {
        key: "fromLocationName",
        label: "From Location",
        editable: false
      },
      {
        key: "toLocationName",
        label: "To Location",
        editable: false
      }
    ]
  },
  tabs: [
    {
      features: [
        {
          id: "transferOrderItemTypesTable",
          title: "Shipment Details"
        },
        {
          id: "transferOrderContainersTable",
          title: "Pallet Details"
        }
      ]
    }
  ],
  subfeatureOptions: {}
};

export const SOLUTION_CONFIGS: { [solutionId: string]: DetailsPageConfigType } = {
  [SOLUTIONS.TRANSFER_ORDER]: DEFAULT_TRANSFER_ORDER_DETAILS_CONFIG
};
