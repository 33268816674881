import { XemelgoService } from "../../../../../../../../../../services/XemelgoService";
import { ACTION_OPTIONS_MAP } from "../../../../../../../../data/constants";
import { queryItemsFromBasicTags } from "../../../../../../../../utils/query-items-from-basic-tags/queryItemsFromBasicTags";

/**
 * @param {Array} itemTypeReports - An array of item type reports.
 * @param {string} itemClass - The class of the items to be onboarded.
 * @param {string} actionType - The type of action being performed, order creation or picklist verification.
 * @returns {Promise<Array<{ itemTypeId: string, items: Array<{itemId: string, sensorProfileId: string}> }>>}
 */
export const onboardItems = async (itemTypeReports, itemClass, actionType) => {
  const inventoryClient = XemelgoService.getClient().getInventoryClient();

  const { epcToCreatedItemAcc: epcToCreatedItem, itemsToQueryAcc: itemsToQuery } = itemTypeReports.reduce(
    (acc, itemTypeReport) => {
      const { epcToCreatedItemAcc = {}, itemsToQueryAcc = [] } = acc;
      const { epcToItemMap } = itemTypeReport;

      Object.entries(epcToItemMap).forEach(([epc, item]) => {
        if (item.id) {
          // item was retrieved on MainPage when scanning tags
          epcToCreatedItemAcc[epc] = {
            ...item,
            itemId: item.id,
            sensorProfileId: item.sensorProfileId
          };
        } else {
          itemsToQueryAcc.push(item);
        }
      });

      return { epcToCreatedItemAcc, itemsToQueryAcc };
    },
    {}
  );

  if (itemsToQuery.length) {
    const existingItems = Object.values(await queryItemsFromBasicTags(itemsToQuery, inventoryClient, {}, [itemClass]));

    existingItems.forEach((item) => {
      epcToCreatedItem[item.vid] = item;
    });
  }

  const payload = itemTypeReports.reduce((acc, itemTypeReport) => {
    const { identifier: itemTypeIdentifer, epcToItemMap } = itemTypeReport;

    Object.entries(epcToItemMap).forEach(([epc, item]) => {
      if (!epcToCreatedItem[epc]) {
        acc.push({
          item_number: itemTypeIdentifer,
          item_identifier: item.identifier,
          tracker_serial: epc,
          class: itemClass
        });
      }
    });

    return acc;
  }, []);

  if (payload.length) {
    const createdItemVids = (await inventoryClient.createItemSet(payload)).createItemSet.map((vid) => {
      return {
        vid
      };
    });
    const createdItems = Object.values(
      await queryItemsFromBasicTags(createdItemVids, inventoryClient, {}, [itemClass])
    );

    createdItems.forEach((item) => {
      epcToCreatedItem[item.vid] = item;
    });
  }

  return formatItemTypeReports(itemTypeReports, epcToCreatedItem, actionType);
};

const formatItemTypeReports = (itemTypeReports, epcToCreatedItem, actionType) => {
  return itemTypeReports.reduce((acc, itemTypeReport) => {
    let { id: itemTypeId } = itemTypeReport;
    const { epcToItemMap } = itemTypeReport;

    const newEpcToItemMap = Object.entries(epcToItemMap)
      .map(([epc, item]) => {
        return {
          ...item,
          ...epcToCreatedItem[epc]
        };
      })
      .filter((item) => {
        if (actionType === ACTION_OPTIONS_MAP.ORDER_CREATION) {
          // Filter out items that are associated already associated with a transfer order
          return item.associatedWithTransferItemState?.length === 0;
        }
        if (actionType === ACTION_OPTIONS_MAP.PICKLIST_VERIFICATION) {
          // Filter out items that are associated with a transfer order other than other the current one
          return item.isAssociatedWithOrder || item.associatedWithTransferItemState?.length === 0;
        }
        return true;
      })
      .reduce((itemAcc, item) => {
        itemAcc[item.vid] = item;
        return itemAcc;
      }, {});

    // If a new type was created through createItemSet, use that type id
    if (!itemTypeId && Object.values(newEpcToItemMap).length) {
      itemTypeId = Object.values(newEpcToItemMap)[0].itemTypeId;
    }

    acc.push({
      ...itemTypeReport,
      itemTypeId,
      epcToItemMap: newEpcToItemMap
    });

    return acc;
  }, []);
};
