/* eslint-disable radix */
import React, { useState, useEffect, useMemo } from "react";
import { ReplayOutlined } from "@material-ui/icons";
import { ReactComponent as SwitchSiteIcon } from "../../assets/icons/switch-site.svg";
import ScreenFrame from "../../components/screen-frame";
import Style from "./ManagementDashboardFeature.module.css";
import FloorMap from "../../components/FloorMap/FloorMap";
import { useXemelgoClient } from "../../services/xemelgo-service";
import "react-loading-skeleton/dist/skeleton.css";
import { useXemelgoAppsyncClient } from "../../services/xemelgo-appsync-service";
import { getFormattedDate } from "../../common/Utilities";
import { STATUS_COLOR_MAP, VIEW_MODE_MAP, TAB_OPTION_MAP, STATUS_SEVERITY_MAP } from "./data/constants";
import useManagementDashboardConfigContext, {
  ManagementDashboardConfigContextProvider
} from "./contexts/management-dashboard-config-context";
import useManagementDashboardDataSourceContext, {
  ManagementDashboardDataSourceContextProvider
} from "./contexts/management-dashboard-data-source-context";
import useManagementDashboardStateContext, {
  ManagementDashboardStateContextProvider
} from "./contexts/management-dashboard-state-context";
import CollapsibleView from "./components/collapsible-view";
import ViewModeSelector from "./features/view-mode-selector";
import SearchBox from "./features/search-box";
import SearchResultContent from "./features/search-result-content";
import SelectedLocationContent from "./features/selected-location-content";
import { ReactComponent as ManagementDashboardIcon } from "../../assets/icons/management-dashboard.svg";
import {
  MANAGEMENT_DASHBOARD_EVENT,
  MANAGEMENT_DASHBOARD_STEPS
} from "../../constants/mixpanel-constant/managementDashboard";
import useMixpanelContext from "../../context/mixpanel-context";
import { SUPPORTED_SETTING_ID_MAP } from "../order-track-page-feature-v2/features/wo-settings-menu/hooks/use-settings-builder/data/constants";
import xemelgoStyle from "../../styles/variable";
import SiteSelectionContent from "./features/site-selection-content";
import PopupFilter from "./features/popup-filter";

const ManagementDashboardFeature = () => {
  const {
    mapConfig,
    isLoading: isConfigLoading,
    workOrderSettings,
    switchSiteControl,
    locationCategories,
    searchControl
  } = useManagementDashboardConfigContext();

  const {
    mapRef,
    isInformationHide,
    setIsInformationHide,
    viewMode,
    selectedMetricType,
    setSelectedMetricType,
    selectedLocationId,
    setSelectedLocationId,
    setSelectedTab,
    showWorkOrderResults,
    isWorkOrdersFilteredByLocationAndMetricsLoading,
    siteLocationId,
    setSiteLocation,
    siteViewState,
    setSiteViewState,
    activeLocationTreeMap,
    filterValue,
    isFilterActive
  } = useManagementDashboardStateContext();

  const {
    setLocationTreeMap,
    setWorkOrdersFilteredByLocationAndMetrics,
    lastUpdatedTime,
    setLastUpdatedTime,
    setAllWorkOrders,
    setIsAllWorkOrdersLoading
  } = useManagementDashboardDataSourceContext();

  const xemelgoClient = useXemelgoClient();
  const xemelgoClientAppSync = useXemelgoAppsyncClient();

  const [managementDashboardClientAppSync] = useState(xemelgoClientAppSync.getManagementDashboardClient());
  const [isMapDataLoading, setIsMapDataLoading] = useState(true);
  const { sendMixPanelEvent } = useMixpanelContext();

  const percentageThresholdKeyMap = useMemo(() => {
    return (
      workOrderSettings?.optionControl?.[SUPPORTED_SETTING_ID_MAP.locationThresholdSettingModal]
        ?.percentageThresholdKeyMap || {}
    );
  }, [workOrderSettings]);

  const getLocationTree = async () => {
    const locationClient = xemelgoClient.getLocationClient();
    const newLocationTreeMap = await locationClient.getLocationTree(
      [...Object.values(percentageThresholdKeyMap)],
      !locationCategories?.length || locationCategories.includes("all") ? null : locationCategories
    );
    return newLocationTreeMap;
  };

  const colorMap = useMemo(() => {
    const warningLocations = [];
    const criticalLocations = [];
    const healthyLocations = [];

    Object.keys(activeLocationTreeMap).forEach((eachLocationId) => {
      const { statuses } = activeLocationTreeMap[eachLocationId];

      const finalStatus = viewMode === VIEW_MODE_MAP.expedited_parts_mode ? statuses.expeditedStatus : statuses.status;

      switch (finalStatus) {
        case STATUS_SEVERITY_MAP.critical:
          criticalLocations.push(eachLocationId);
          break;
        case STATUS_SEVERITY_MAP.warning:
          warningLocations.push(eachLocationId);
          break;
        case STATUS_SEVERITY_MAP.healthy:
        default:
          healthyLocations.push(eachLocationId);
          break;
      }
    });
    return {
      [STATUS_COLOR_MAP.Healthy]: {
        label: STATUS_SEVERITY_MAP.healthy,
        locations: healthyLocations
      },
      [STATUS_COLOR_MAP.Warning]: {
        label: STATUS_SEVERITY_MAP.warning,
        locations: warningLocations
      },
      [STATUS_COLOR_MAP.Critical]: {
        label: STATUS_SEVERITY_MAP.critical,
        locations: criticalLocations
      }
    };
  }, [activeLocationTreeMap, viewMode]);

  const getMetricsForAllLocations = async (locationTree) => {
    const [metrics, metricUpdatedTime] = await managementDashboardClientAppSync.getWorkOrderMetricsByLocationIds(
      Object.keys(locationTree).filter((eachLocationId) => {
        return locationTree[eachLocationId].childLocations.length <= 1;
      })
    );
    setLastUpdatedTime(metricUpdatedTime);
    return metrics;
  };

  const getAllWorkOrdersForAllLeafLocations = async (locationTree) => {
    const leafLocationIds = Object.keys(locationTree).filter((eachLocationId) => {
      return locationTree[eachLocationId].childLocations.length <= 1;
    });
    const workOrderList = await managementDashboardClientAppSync.getWorkOrdersFilteredByLocationAndStatus(
      leafLocationIds
    );
    return workOrderList;
  };

  const getLocationTreeWithMetrics = async () => {
    const locationTree = await getLocationTree();
    const newLocationTreeMap = locationTree;
    const metrics = await getMetricsForAllLocations(locationTree);

    getAllWorkOrdersForAllLeafLocations(locationTree).then((newAllWorkOrders) => {
      setAllWorkOrders(newAllWorkOrders);
      setIsAllWorkOrdersLoading(false);
    });

    Object.keys(newLocationTreeMap).forEach((eachLocationId) => {
      newLocationTreeMap[eachLocationId].metrics = {};
    });
    Object.keys(metrics).forEach((eachLocationId) => {
      if (newLocationTreeMap[eachLocationId]) {
        newLocationTreeMap[eachLocationId].metrics = { ...metrics[eachLocationId] };
      }
    });

    Object.keys(newLocationTreeMap)
      .filter((eachLocationId) => {
        return newLocationTreeMap[eachLocationId].childLocations.length <= 1;
      })
      .forEach((eachLeafLocationId) => {
        let currentLocation = newLocationTreeMap[eachLeafLocationId];
        if (!currentLocation.metrics) {
          currentLocation.metrics = {};
        }
        while (currentLocation.directParentId) {
          Object.keys(currentLocation?.metrics).forEach((eachMetricKey) => {
            if (!newLocationTreeMap[currentLocation.directParentId].metrics[eachMetricKey]) {
              newLocationTreeMap[currentLocation.directParentId].metrics[eachMetricKey] = 0;
            }
            newLocationTreeMap[currentLocation.directParentId].metrics[eachMetricKey] +=
              newLocationTreeMap[eachLeafLocationId].metrics[eachMetricKey] || 0;
          });

          currentLocation = newLocationTreeMap[currentLocation.directParentId];
        }
      });

    Object.keys(newLocationTreeMap).forEach((eachLocationId) => {
      const eachLocation = newLocationTreeMap[eachLocationId];

      const statusCount = {
        totalCriticalCount: 0,
        totalWarningCount: 0,
        totalExpeditedCriticalCount: 0,
        totalExpeditedWarningCount: 0
      };

      Object.keys(eachLocation.metrics).forEach((eachMetricsKey) => {
        if (eachMetricsKey.toLowerCase().includes(STATUS_SEVERITY_MAP.critical.toLowerCase())) {
          statusCount.totalCriticalCount += eachLocation.metrics[eachMetricsKey];
          if (eachMetricsKey.toLowerCase().includes(STATUS_SEVERITY_MAP.expedited.toLocaleLowerCase())) {
            statusCount.totalExpeditedCriticalCount += eachLocation.metrics[eachMetricsKey];
          }
        } else if (eachMetricsKey.toLowerCase().includes(STATUS_SEVERITY_MAP.warning.toLowerCase())) {
          statusCount.totalWarningCount += eachLocation.metrics[eachMetricsKey];
          if (eachMetricsKey.toLowerCase().includes(STATUS_SEVERITY_MAP.expedited.toLocaleLowerCase())) {
            statusCount.totalExpeditedWarningCount += eachLocation.metrics[eachMetricsKey];
          }
        }
      });

      eachLocation.metrics = { ...eachLocation.metrics, ...statusCount };
      const {
        metrics: {
          totalWarningCount,
          totalCriticalCount,
          totalExpeditedWarningCount,
          totalExpeditedCriticalCount,
          totalCount
        }
      } = eachLocation;

      const {
        [percentageThresholdKeyMap.warning]: warningPercentageThreshold,
        [percentageThresholdKeyMap.critical]: criticalPercentageThreshold
      } = eachLocation;

      let status = STATUS_SEVERITY_MAP.healthy;
      if (
        warningPercentageThreshold
          ? parseInt((totalWarningCount / totalCount) * 100) >= warningPercentageThreshold
          : totalWarningCount
      ) {
        status = STATUS_SEVERITY_MAP.warning;
      }
      if (
        criticalPercentageThreshold
          ? parseInt((totalCriticalCount / totalCount) * 100) >= criticalPercentageThreshold
          : totalCriticalCount
      ) {
        status = STATUS_SEVERITY_MAP.critical;
      }

      let expeditedStatus = STATUS_SEVERITY_MAP.healthy;
      if (totalExpeditedWarningCount) {
        expeditedStatus = STATUS_SEVERITY_MAP.warning;
      }
      if (totalExpeditedCriticalCount) {
        expeditedStatus = STATUS_SEVERITY_MAP.critical;
      }

      eachLocation.statuses = { status, expeditedStatus };
    });

    setLocationTreeMap(newLocationTreeMap);
    setIsMapDataLoading(false);
  };

  useEffect(() => {
    sendMixPanelEvent(MANAGEMENT_DASHBOARD_EVENT, MANAGEMENT_DASHBOARD_STEPS.ENTRY);
  }, []);

  useEffect(() => {
    if (!isConfigLoading) {
      setIsMapDataLoading(true);
      getLocationTreeWithMetrics();
      sendMixPanelEvent(MANAGEMENT_DASHBOARD_EVENT, MANAGEMENT_DASHBOARD_STEPS.MAP_LOADED);
      const autoRefreshInterval = setInterval(() => {
        getLocationTreeWithMetrics();
      }, 1000 * 60 * 30);
      return () => {
        clearInterval(autoRefreshInterval);
      };
    }
  }, [isConfigLoading]);

  const TitleRightComponent = () => {
    return (
      <div className={`${Style.flex_row} ${Style.title_right_container}`}>
        {switchSiteControl?.enabled && (
          <div
            className={`${Style.title_right_reload_button} ${Style.flex_column}`}
            onClick={() => {
              setSelectedLocationId(null);
              setSiteLocation(null, {});
              mapRef.current.resetMap({
                shouldTriggerCallback: false,
                viewState: mapConfig.initialViewStates || {}
              });
            }}
          >
            <SwitchSiteIcon
              width={30}
              height={30}
            />
            <p>{`Select ${switchSiteControl?.locationCategory || "Site"}`}</p>
          </div>
        )}
        <div
          className={`${Style.title_right_reload_button} ${Style.flex_column}`}
          onClick={async () => {
            if (!isWorkOrdersFilteredByLocationAndMetricsLoading) {
              await getLocationTreeWithMetrics();
              setSelectedMetricType(selectedMetricType);
            }
          }}
        >
          <ReplayOutlined />
          <p>Reload</p>
        </div>
        <div>
          <p>Last Updated:</p>
          <p>{lastUpdatedTime ? getFormattedDate(lastUpdatedTime) : "-"}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.resetMap({
        shouldTriggerCallback: true,
        viewState: siteViewState
      });
    }
  }, [viewMode, filterValue, mapRef]);

  return (
    <ScreenFrame
      verticalExpandDisabled
      title="Management Dashboard"
      color={xemelgoStyle.theme.XEMELGO_BLUE}
      secondaryColor={xemelgoStyle.theme.XEMELGO_LIGHTBLUE}
      titleIconComponent={() => {
        return (
          <ManagementDashboardIcon
            width={30}
            height={30}
            style={{ color: xemelgoStyle.theme.XEMELGO_BLUE }}
          />
        );
      }}
      titleRightComponent={<TitleRightComponent />}
    >
      {!isMapDataLoading && !isConfigLoading && (
        <FloorMap
          ref={mapRef}
          mapInitializedCallback={() => {
            mapRef.current.selectMarker(siteLocationId, true).then(({ properties, center }) => {
              setSiteViewState(
                properties?.viewStates && center
                  ? {
                      ...JSON.parse(properties.viewStates),
                      zoom: properties?.zoom || 0,
                      center
                    }
                  : null
              );
            });
          }}
          mapStyleURL={mapConfig.mapStyleURL || ""}
          floorPlanTilesets={mapConfig.floorPlanTilesets || []}
          locationsSourceTilesets={mapConfig.locationsSourceTilesets || []}
          initialViewStates={
            !switchSiteControl?.enabled
              ? mapConfig.initialViewStates || {}
              : !siteLocationId
              ? mapConfig.initialViewStates || {}
              : siteViewState || mapConfig.initialViewStates || {}
          }
          markersGeojson={mapConfig?.markersGeojson || null}
          colorMap={colorMap}
          mapCameraPadding={!isInformationHide && { right: 650 }}
          mapNavigationControlClassName={`${Style.map_zoom_control} ${
            isInformationHide && Style.map_zoom_control_collapsed
          }`}
          mapColorIndicatorClassName={`${Style.map_color_indicator} `}
          popupEnabled
          popupRenderer={(locationId) => {
            return <div>{activeLocationTreeMap[locationId]?.name}</div>;
          }}
          onLocationClicked={(id, data) => {
            const locationId = id || siteLocationId;
            const location = activeLocationTreeMap[locationId];
            if (locationId !== selectedLocationId || !location) {
              setWorkOrdersFilteredByLocationAndMetrics([]);
              setSelectedMetricType(null);
            }
            setSelectedTab(TAB_OPTION_MAP.jobStatusTab);
            setSelectedLocationId(locationId);

            const { properties, center } = data || {};
            if (!siteLocationId) {
              setSiteLocation(id, {
                ...JSON.parse(properties?.viewStates || "{}"),
                zoom: properties?.zoom,
                center
              });
            }
          }}
          hideIds={
            isFilterActive
              ? viewMode === VIEW_MODE_MAP.expedited_parts_mode
                ? Object.keys(activeLocationTreeMap).filter((locationId) => {
                    return !activeLocationTreeMap[locationId].metrics.totalExpeditedCount;
                  })
                : Object.keys(activeLocationTreeMap).filter((locationId) => {
                    return !activeLocationTreeMap[locationId].metrics.totalCount;
                  })
              : []
          }
        >
          <div
            className={`${Style.flex_column} ${Style.top_right_action_container} ${
              isInformationHide && Style.top_right_action_container_collapsed
            }`}
          >
            <div className={`${Style.top_right_action}`}>
              <PopupFilter />
            </div>
          </div>
          <div className={`${Style.flex_column} ${Style.top_left_action_container}`}>
            {searchControl?.length && (
              <div className={`${Style.flex_row} ${Style.top_left_action}`}>
                <SearchBox />
              </div>
            )}
            <div className={`${Style.view_mode_container} ${Style.top_left_action}`}>
              <ViewModeSelector />
            </div>
          </div>
          <CollapsibleView
            isCollapsed={isInformationHide}
            onClick={() => {
              setIsInformationHide((currentValue) => {
                return !currentValue;
              });
            }}
          >
            {switchSiteControl?.enabled && !siteLocationId ? (
              <SiteSelectionContent />
            ) : showWorkOrderResults ? (
              <SearchResultContent />
            ) : (
              <SelectedLocationContent />
            )}
          </CollapsibleView>
        </FloorMap>
      )}
    </ScreenFrame>
  );
};

export default () => {
  return (
    <ManagementDashboardConfigContextProvider>
      <ManagementDashboardDataSourceContextProvider>
        <ManagementDashboardStateContextProvider>
          <ManagementDashboardFeature />
        </ManagementDashboardStateContextProvider>
      </ManagementDashboardDataSourceContextProvider>
    </ManagementDashboardConfigContextProvider>
  );
};
