import React from "react";
import Style from "./ImageWithText.module.css";
import ImageWithEnlargedModalView from "../../../image-with-enlarged-modal-view";
import logo from "../../../../assets/images/minimal_xemelgo_logo.png";

interface ImageWithTextProps {
  imageSrc?: string;
  text?: string;
  imageSize?: number;
}

export const ImageWithText = ({ imageSrc = "", text = "", imageSize = 50 }: ImageWithTextProps) => {
  return (
    <div className={Style.container}>
      <div
        className={Style.image_container}
        style={{
          minWidth: imageSize,
          height: imageSize
        }}
      >
        <ImageWithEnlargedModalView
          imageUri={imageSrc || logo}
          classOverrides={{
            imageContainerStyle: Style.image_container,
            imageStyle: Style.image
          }}
        />
      </div>
      <p>{text}</p>
    </div>
  );
};
