import { useMemo } from "react";
import { getValueByType } from "../../../../../../../../components/paginated-list-table-with-types";

export const useGetFilteredDataList = ({ freeTextSearchInputString = "", filterCallbackFn, dataList, headers }) => {
  const filteredDataList = useMemo(() => {
    if (!freeTextSearchInputString && typeof filterCallbackFn !== "function") {
      return dataList;
    }
    const filterStringList = freeTextSearchInputString
      .toLowerCase()
      .split(" ")
      .filter((eachString) => {
        return eachString;
      });

    return dataList.filter((eachEntry) => {
      let shouldInclude = true;
      if (filterStringList.length) {
        const displayString = headers.reduce((accumulator, eachHeader) => {
          return accumulator + getValueByType(eachEntry[eachHeader.id], eachHeader, true);
        }, "");

        filterStringList.forEach((eachFilterString) => {
          if (!displayString.toString().toLowerCase().includes(eachFilterString.toLowerCase())) {
            shouldInclude = false;
          }
        });
      }

      if (shouldInclude && typeof filterCallbackFn === "function") {
        shouldInclude = filterCallbackFn(eachEntry);
      }

      return shouldInclude;
    });
  }, [freeTextSearchInputString, dataList, headers, filterCallbackFn]);
  return filteredDataList;
};
