import React from "react";
import Style from "./Spinner.module.css";
import xemelgoStyle from "../../styles/variable";

interface SpinnerProps {
  color?: string;
  size?: number;
  strokeWidth?: number;
  speed?: number;
}

export const Spinner: React.FC<SpinnerProps> = ({
  color = xemelgoStyle.theme.APP_BLUE,
  size = 40,
  strokeWidth = 4,
  speed = 1.5
}) => {
  return (
    <div
      className={Style.spinner}
      style={{ color, width: size, height: size, borderWidth: strokeWidth, animationDuration: `${speed}s` }}
    />
  );
};
