import React, { useState, useEffect, useRef, Fragment } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { useStore } from "react-hookstore";
import { configStore } from "state-managements/stores/config-store";
import Skeleton from "react-loading-skeleton";
import TextFilterInputComponent from "components/text-filter-input-component/TextFilterInputComponent";
import { parseQueryString, stringifyToQueryString } from "../../common/Utilities";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import Style from "./ScheduleTrackPageFeature.module.css";
import ViewTabComponent from "../../components/ViewTabComponent";
import ScreenFrame from "../../components/screen-frame";
import { useScheduleManagementContext } from "./context/ScheduleManagementContext.context";
import { SideFilter } from "./features/SideFilter";
import { WorkCentersGroup } from "./components/work-centers-group/WorkCentersGroup";
import ScheduleWorkOrderModal from "./components/schedule-work-order-modal/ScheduleWorkOrderModal";
import { useXemelgoClient } from "../../services/xemelgo-service";

const APP_ID = "scheduleOrder";
const TRACK_PAGE_CONFIG = "trackPage";
const MAIN_COLOR = "#008E73";
const SECONDARY_COLOR = "#D6EAE6";

const ScheduleTrackPageFeature = ({ history }) => {
  // configuration
  const configProvider = useAppConfigProvider(APP_ID);
  const TrackPageConfig = configProvider.getValue(TRACK_PAGE_CONFIG, "object");
  const [customerConfiguration] = useStore(configStore);
  // services
  const xemelgoClient = useXemelgoClient();
  const [scheduleOrderClient] = useState(xemelgoClient.getScheduleOrderClient());
  // state variables
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentViewTab, setCurrentViewTab] = useState("");
  const [viewTabStructure, setViewTabStructure] = useState({});
  const urlRef = useRef({ pathname: history.location.pathname, search: history.location.search });

  // context variables
  const { registerTrackPageConfiguration, registerCustomerConfig, setFilterInputs, setSecondaryColor } =
    useScheduleManagementContext();

  const handleTextFilterOnChange = (textInput) => {
    const textFilterInput = {
      any: { value: [textInput] }
    };
    setFilterInputs((existingFilters) => {
      return {
        ...existingFilters,
        ...textFilterInput
      };
    });
  };

  const getViewTabStructure = (workCenterGroups) => {
    const viewTabMap = {};
    workCenterGroups.forEach((workCenterGroup) => {
      const { id, identifier } = workCenterGroup;
      viewTabMap[id] = {
        id,
        label: identifier,
        onClick: () => {
          handleViewTabClick(id);
        }
      };
    });
    return viewTabMap;
  };

  const handleViewTabClick = (id) => {
    const queryPayload = parseQueryString(history.location.search);
    queryPayload.listView = true;
    const queryString = stringifyToQueryString({
      ...queryPayload,
      viewTab: id
    });
    history.replace(`${history.location.pathname}?${queryString}`);
  };

  useEffect(() => {
    const queryPayload = parseQueryString(history.location.search);
    const { viewTab } = queryPayload;
    viewTab && setCurrentViewTab(viewTab);
    urlRef.current = history.location;
  }, [history.location, loading]);

  useEffect(() => {
    let queryPayload = parseQueryString(history.location.search);
    const breadcrumbIds = queryPayload.breadcrumbIds ? JSON.parse(queryPayload.breadcrumbIds) : [];
    const { status, listView = true } = queryPayload;
    queryPayload = parseQueryString(history.location.search);

    const queryString = stringifyToQueryString({
      ...queryPayload,
      listView,
      breadcrumbIds: JSON.stringify(breadcrumbIds),
      status
    });

    history.replace(`${history.location.pathname}?${queryString}`);
  }, []);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    setLoading(true);
    setSecondaryColor(SECONDARY_COLOR);
    registerTrackPageConfiguration(TrackPageConfig);
    registerCustomerConfig(customerConfiguration);
    const workCenterGroups = await scheduleOrderClient.getWorkCenterGroups();
    const queryPayload = parseQueryString(history.location.search);
    const { viewTab } = queryPayload;
    setCurrentViewTab(viewTab || workCenterGroups[0]?.id);
    setViewTabStructure(getViewTabStructure(workCenterGroups));
    setLoading(false);
  };

  return (
    <ScreenFrame
      title="Scheduling"
      color={MAIN_COLOR}
      secondaryColor={SECONDARY_COLOR}
      titleIconComponent={() => {
        return (
          <img
            src={require("../../img/scheduling-management.png")}
            width="40px"
            height="40px"
          />
        );
      }}
    >
      <div className={Style.main_container}>
        <div className={Style.side_container}>
          <div className={Style.feature_buttons}>
            <div
              className={Style.create_button}
              style={{ backgroundColor: MAIN_COLOR }}
              onClick={() => {
                window.fcWidget.hide();
                setShowModal(true);
              }}
            >
              Schedule Order
            </div>
          </div>
          {showModal && (
            <ScheduleWorkOrderModal
              onCloseClick={() => {
                setShowModal(false);
                onLoad();
                window.fcWidget.show();
              }}
            />
          )}
          <SideFilter />
        </div>
        <div className={Style.informational_container}>
          <div className={Style.data_container}>
            <div className={Style.search_and_buttons_group}>
              <TextFilterInputComponent
                placeholder={`Type to filter ${
                  viewTabStructure[currentViewTab] ? viewTabStructure[currentViewTab].label : "..."
                }`}
                onChange={handleTextFilterOnChange}
              />
            </div>
            {loading ? (
              <Skeleton
                className={Style.skeleton_header}
                count={4}
                height={34}
              />
            ) : (
              <>
                <div className={Style.view_container}>
                  <ViewTabComponent
                    current={currentViewTab}
                    data={Object.values(viewTabStructure)}
                    mainLabel="View: "
                    mainLabelClassname={Style.view_label}
                    buttonClassname={Style.view_btn}
                    buttonActiveClassname={`${Style.view_btn_active}`}
                  />
                </div>
                <div className={Style.datalist_container}>
                  {Object.keys(viewTabStructure).length ? (
                    <WorkCentersGroup workCentersGroupId={currentViewTab} />
                  ) : (
                    <div className={Style.no_data}>No data available to show</div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </ScreenFrame>
  );
};
export default withRouter(ScheduleTrackPageFeature);
