import React, { useState } from "react";
import Style from "../../PaginatedListTableWithTypes.module.css";
import { ReactComponent as ChevronRight } from "../../../../assets/icons/chevron-right-blue.svg";
import OptionalLink from "../../../optional-link/OptionalLink";
import { getValueByType } from "../../utils/get-value-by-type";
import OptionsTooltip from "../options-tooltip";
import type { Header, Item } from "../../data/types";
import { VALUE_TYPE_MAP } from "../../data/constants";

interface TableRowProps {
  headers: Header[];
  item: Item;
  selectable?: boolean;
  showRowOptions?: boolean;
  isExpandable?: boolean;
  itemRowContainerClassName?: string;
  itemCellContainerClassName?: string;
  onRowOptionClick?: (item: any, option: any) => void;
  getTitleURLCallbackFn: (item: any, headerId: string) => string;
}

export const TableRow = ({
  headers,
  item,
  selectable,
  itemRowContainerClassName,
  showRowOptions,
  isExpandable,
  onRowOptionClick,
  itemCellContainerClassName,
  getTitleURLCallbackFn = () => {
    return "";
  }
}: TableRowProps) => {
  const { id, statusAccentColor, rowOptions, expandedRowComponent: ExpandedRowComponent } = item;

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      key={id}
      className={`${selectable ? Style.table_item_selectable : Style.table_item} ${itemRowContainerClassName}`}
      style={{
        borderLeftColor: statusAccentColor
      }}
      data-cy={`table-row-${id}`}
    >
      <div className={`${Style.flex_row}`}>
        {isExpandable && (
          <div className={Style.chevron_container}>
            <ChevronRight
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
              className={`${Style.chevron} ${isExpanded ? Style.chevron_down : ""}`}
              data-cy="expand-table-row-button"
            />
          </div>
        )}
        {headers.map((eachHeader) => {
          const { id: headerId, openLinkInNewTab, type, isNavLink } = eachHeader;

          const route = getTitleURLCallbackFn(item, headerId);
          const isClickable = !!isNavLink && !!route;

          return (
            <OptionalLink
              key={`${id}-${headerId}`}
              className={`${Style.flex_row} ${Style.table_column} ${itemCellContainerClassName} ${
                type === VALUE_TYPE_MAP.comment && Style.comment_column
              }`}
              active={isClickable}
              openLinkInNewTab={openLinkInNewTab}
              route={route}
            >
              <div className={`${Style.table_item_text} ${isClickable && Style.table_item_text_clickable}`}>
                {getValueByType(item[headerId], eachHeader)}
              </div>
            </OptionalLink>
          );
        })}
        {showRowOptions && rowOptions?.length > 0 && (
          <OptionsTooltip
            item={item}
            rowOptions={rowOptions}
            onRowOptionClick={onRowOptionClick}
          />
        )}
      </div>
      {isExpanded && ExpandedRowComponent && <ExpandedRowComponent item={item} />}
    </div>
  );
};
