import React, { useState, useEffect } from "react";
import { useDisplayBannerContext } from "context/DisplayBannerContext/DisplayBannerContext";
import Spinner from "react-bootstrap/Spinner";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { FeatureConfigurationProvider } from "../../../../domains/feature-configuration-provider";
import InputGroup from "../../../../components/my-facility-v2-components/InputGroup";
import { ReactComponent as MyFacilityIcon } from "../../../../assets/icons/my-facility.svg";
import OnboardItemModal from "components/onboard-item-modal";
import EditDetectorFormV2Style from "./EditDetectorFormV2.module.css";
import "./EditDetectorFormV2.css";

const FeatureId = "editResource";
export const EditDetectorFormV2 = ({ configuration, modelId, show, providedArgument, resource, onCancel, onSave }) => {
  const { setShowBanner, setBannerTitle, setBannerHasError } = useDisplayBannerContext();
  const [loading, setLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const [editedProperties, setEditedProperties] = useState({});
  const [xemelgoClient] = useState(useXemelgoClient());

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!configuration || !modelId || !resource || !providedArgument) {
      return cancelCallback;
    }

    const configProvider = FeatureConfigurationProvider.parse(FeatureId, configuration);
    const propertyOrders = configProvider.getValue("propertyOrders", "array", []);
    const modelConfigProvider = configProvider.getModel(modelId);
    const propertyMap = modelConfigProvider.getPropertyMap();

    const props = propertyOrders
      .filter((propertyId) => {
        return propertyMap[propertyId];
      })
      .map((propertyId) => {
        const property = propertyMap[propertyId];
        const { displayName, __updatable: editable, optionsProvided, name = propertyId } = property;

        let { options } = property;
        if (optionsProvided) {
          options = providedArgument[propertyId];
        }

        if (options && options.length) {
          options = options.map((option) => {
            return {
              id: option.key,
              label: option.value
            };
          });
        }
        const propertyName = name === "locationId" ? "location" : name;

        return {
          options,
          name: propertyName,
          displayName,
          disabled: !editable
        };
      });

    if (!cancelled) {
      setProperties(props);
      setLoading(false);
    }

    return cancelCallback;
  }, [configuration, modelId, resource, providedArgument]);

  const handleEdit = (id, value) => {
    const tempEditedProperties = { ...editedProperties };
    tempEditedProperties[id] = value;
    setEditedProperties(tempEditedProperties);
  };

  const renderLoading = () => {
    return (
      <div className={EditDetectorFormV2Style.loading_circle}>
        <Spinner animation="border" />
      </div>
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    const detectorClient = xemelgoClient.getDetectorClient();
    const tempEditedProperties = { ...editedProperties };
    const { location, action, mode } = tempEditedProperties;
    delete tempEditedProperties.location;
    if (location) {
      await detectorClient.changeDetectorLocation(resource.id, location.id);
    }

    if (action || mode) {
      tempEditedProperties.action = tempEditedProperties.action?.id;
      tempEditedProperties.mode = tempEditedProperties.mode?.id;
    }

    let actions;
    if (action) {
      delete tempEditedProperties.action;
      switch (action.id) {
        case "endTrackingSession":
          actions = {
            endTrackingSession: {
              detachSensorProfiles: true
            }
          };
          break;
        case "sameDetectorEndTrackingSession":
          actions = {
            sameDetectorEndTrackingSession: true
          };
          break;
        case "shipping":
          actions = {
            shipment: {
              value: "In Transit"
            }
          };
          break;
        case "receiving":
          actions = {
            shipment: {
              value: "Received"
            }
          };
          break;
        case "increaseItemUsage":
          actions = {
            increaseItemUsage: true,
            resetRefreshDate: true
          };
          break;
        case "resetItemUsage":
          actions = {
            resetItemUsage: true
          };
          break;
        case "entryExit":
          actions = {
            flipHasExitState: true
          };
          break;
        default:
          break;
      }
      tempEditedProperties.actions = actions;
    }

    if (Object.keys(tempEditedProperties).length > 0) {
      await detectorClient.updateDetectorV2(resource.id, tempEditedProperties);
    }
    setBannerHasError(false);
    setBannerTitle(`Successfully updated ${resource.name}`);
    setShowBanner(true);
    setLoading(false);
    onSave();
  };

  return (
    <OnboardItemModal
      scrollable
      show={show}
      prefix="location"
      className={EditDetectorFormV2Style.location_modal}
      titleIconComponent={
        <MyFacilityIcon
          width={42}
          height={42}
          className={EditDetectorFormV2Style.title_icon}
        />
      }
      submitDisabled={false}
      submitLoading={loading}
      onSubmit={handleSubmit}
      onClose={onCancel}
      modalContainerClassName={EditDetectorFormV2Style.modal_container}
      title={`Edit ${resource.name}`}
    >
      {loading ? (
        renderLoading()
      ) : (
        <div>
          <InputGroup
            title="Detector Information"
            properties={properties}
            onChange={handleEdit}
            values={editedProperties}
            placeholders={resource}
          />
        </div>
      )}
    </OnboardItemModal>
  );
};
