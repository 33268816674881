import { ReactComponent as PrintIcon } from "../../../assets/icons/printer.svg";
import { ReactComponent as ArchiveIcon } from "../../../assets/icons/archive.svg";

export const TAB_OPTION_MAP = {
  assetTab: "asset",
  assetTypeTab: "assetType"
};

export const LOCATION_SORT_OPTIONS = {
  asc: "A-Z",
  desc: "Z-A"
};

export const MULTI_SELECT_ACTION_ICONS = {
  printTags: PrintIcon,
  markAsComplete: ArchiveIcon
};
