import { getValueByType } from "../get-value-by-type";
import { exportCsv } from "../../../../common/Utilities";
import { Header } from "../../data/types";

export const createCSV = <T extends Record<string, unknown>>(data: T[], headers: Header[], fileName: string) => {
  const csvData: T[] = [];
  const csvColumns = headers.filter((column) => {
    return column.label;
  });

  data.forEach((row) => {
    const rowData = csvColumns.reduce((acc: Record<string, unknown>, header) => {
      if (!header.label || !row[header.id]) {
        return acc;
      }

      acc[header.label] = getValueByType(row[header.id], header, true);
      return acc;
    }, {});

    csvData.push(rowData as T);
  });

  exportCsv(
    csvData,
    {
      header: csvColumns.map((column) => {
        return column.label;
      }),
      cellDates: true
    },
    fileName
  );
};
