import React, { useMemo } from "react";
import { DEFAULT_TRANSFER_ORDER_ITEMS_TABLE_HEADERS } from "./data/constants";
import { Header, Item } from "../../../../components/paginated-list-table-with-types";
import { DetailsPageTable } from "../../../details-page";
import { ITEM_CLASS_TO_URL_PATH } from "../transfer-order-item-types-table/data/constants";
import { TransferOrderItemType } from "../../contants/types";

type TransferOrderItemsOptions = {
  csvFileName?: string;
  headers?: Header[];
};

export const TransferOrderItems = ({
  itemTypes,
  transferOrderIdentifier,
  options
}: {
  itemTypes: TransferOrderItemType[];
  transferOrderIdentifier: string;
  options: TransferOrderItemsOptions;
}) => {
  const { csvFileName = "Associated Item Details", headers = DEFAULT_TRANSFER_ORDER_ITEMS_TABLE_HEADERS } =
    options || {};

  const transferOrderItems: Item[] = useMemo(() => {
    return itemTypes.reduce((acc: Item[], itemType) => {
      const { items } = itemType;

      items.forEach((item) => {
        const { class: itemClass } = item || { hasSensorProfile: [] };

        if (itemClass === "Container") {
          return;
        }

        acc.push(item);
      });

      return acc;
    }, []);
  }, [itemTypes]);

  return (
    <DetailsPageTable
      paginatorLocation="top"
      headers={headers}
      data={transferOrderItems}
      csvFileName={`${csvFileName} - ${transferOrderIdentifier}`}
      getTitleURLCallbackFn={(item: any, headerId: string) => {
        if (headerId.includes("container")) {
          return item.container_id
            ? `/${ITEM_CLASS_TO_URL_PATH.Container}/item/detail?itemId=${item.container_id}`
            : "";
        }

        const path = ITEM_CLASS_TO_URL_PATH[item.class];
        if (path && item.id) {
          return `/${path}/item/detail?itemId=${item.id}`;
        }

        return "";
      }}
      sortIdReplacementMap={{
        status: "statusSortValue"
      }}
      numItemsPerPage={5}
    />
  );
};
