import React from "react";
import useDetailsPageConfigContext from "../../contexts/details-page-config-context";
import DetailCard from "../../../../components/TrackPageComponents/DetailCard";
import { DetailsCardData } from "./data/types";

export const DetailsCardV2 = ({
  editEnabled,
  detailsCardData
}: {
  editEnabled?: boolean;
  detailsCardData: DetailsCardData;
}) => {
  const { customText } = useDetailsPageConfigContext();

  return (
    <DetailCard
      isLoading={!detailsCardData}
      titleStructure={
        detailsCardData
          ? {
              key: detailsCardData.id,
              name: customText.subtitle,
              value: detailsCardData.identifier,
              editable: false
            }
          : undefined
      }
      detailStructureList={detailsCardData?.displayedProperties || []}
      statusList={detailsCardData?.statuses || []}
      editEnabled={editEnabled}
    />
  );
};
