import React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import Switch from "react-switch";
import { TextareaAutosize } from "@material-ui/core";
import Datetime from "react-datetime";
import Style from "./DetailsCardDisplayedFields.module.css";
import { convertMillisecondsToUnitsOfTime, getFormattedDate } from "../../../../common/Utilities";
import OptionalLink from "../../../optional-link/OptionalLink";
import Selector from "../../../selector";
import useAvoidRacingAPIHelper from "../../../../hooks/use-avoid-racing-api-helper";
import { OnboardingV2FunctionMap } from "../../../../common/commonAPICalls";

const statusColor = {
  delayed: "#F62227",
  expedited: "#e3621d",
  completed: "#C6CBD4",
  "on-time": "#00B200"
};

type DetailCardField = {
  key: string;
  value: unknown;
  editable: boolean;
  type: string;
  editOptions?: {
    itemTypeClass: string;
    type?: string;
    placeholder?: string;
  };
  color: string;
  format: string;
  relativeDayCount?: {
    show: boolean;
    color: string;
    value: string;
  };
  options?: { id: string; label: string }[];
  linkOptions?: {
    displayTextField?: string;
    path?: string;
    openLinkInNewTab?: boolean;
  };
  convertFromMilliseconds: boolean;
};

interface DetailsCardDisplayedFieldsProps {
  isEditing: boolean;
  fields: DetailCardField[];
  details: Record<string, any>;
  isActive: boolean;
  onStateChange: (key: string, value: any, type: string) => void;
  onExpeditedStatusChange?: (enable: boolean) => void;
}

export const DetailsCardDisplayedFields = ({
  fields,
  isEditing,
  isActive,
  onStateChange,
  onExpeditedStatusChange,
  details
}: DetailsCardDisplayedFieldsProps) => {
  const executeFn = useAvoidRacingAPIHelper();

  const renderValueField = (field: DetailCardField) => {
    const {
      key,
      editable,
      type = "default",
      editOptions,
      color = null,
      format,
      relativeDayCount = { show: false, color: "black", value: "" },
      options,
      linkOptions = {},
      convertFromMilliseconds
    } = field;

    let { value } = field;
    if (convertFromMilliseconds) {
      value = convertMillisecondsToUnitsOfTime(value);
    }

    const stateValue = details[key];
    const { displayTextField, path, openLinkInNewTab } = linkOptions || {};

    if (!isEditing || !editable) {
      switch (type.toLowerCase()) {
        case "internallink":
          // Use displayTextField to display values like item type identifier in the link
          const { id, [displayTextField]: displayText = "-" } = (value as Record<string, any>) || {};
          const fullPath = `${path}${id}` || "";
          const isValidLink = !!fullPath && !isEditing;

          return (
            <div className={Style.detail_value}>
              <OptionalLink
                key={key}
                active={isValidLink}
                route={fullPath}
                openLinkInNewTab={openLinkInNewTab}
              >
                <p className={isValidLink ? Style.clickable_text : Style.detail_text}>{displayText}</p>
              </OptionalLink>
            </div>
          );
        case "switch":
          return (
            <Switch
              onColor={statusColor.expedited}
              id={key}
              checked={!!stateValue}
              disabled={!editable}
              onChange={(enable) => {
                if (key === "expedited") {
                  onExpeditedStatusChange(enable);
                } else {
                  onStateChange(key, enable, "switch");
                }
              }}
              handleDiameter={24}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              className={Style.react_switch}
            />
          );
        case "date":
        case "datepicker":
          return (
            <div
              id={key}
              className={`${Style.detail_value} ${color ? Style[color] : null}`}
            >
              {getFormattedDate(value, format || "MM/DD/YYYY")}
              {relativeDayCount.show && relativeDayCount.value && (
                <TextareaAutosize
                  id={`${key}_label`}
                  className={`${Style.exp_date_label} ${Style.detail_value}`}
                  style={{ color: relativeDayCount.color || "black" }}
                  value={relativeDayCount.value}
                  disabled
                />
              )}
            </div>
          );
        default:
          return (
            <div
              id={key}
              className={`${Style.detail_value} ${color ? Style[color] : null}`}
            >
              {value}
            </div>
          );
      }
    }

    switch (editOptions?.type || type.toLowerCase()) {
      case "switch":
        return (
          <Switch
            onColor={statusColor.expedited}
            id={key}
            checked={!!stateValue}
            onChange={(enable) => {
              onStateChange(key, enable, "switch");
            }}
            handleDiameter={24}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className={Style.react_switch}
          />
        );
      case "date":
      case "datepicker":
        return (
          <div className={Style.exp_date_field}>
            <Datetime
              className={Style.date_time}
              defaultValue="-"
              value={stateValue}
              inputProps={{
                className: Style.date_time_value_input
              }}
              onChange={(moment) => {
                const date = moment.toString();
                onStateChange(key, Date.parse(date) || null, "date");
              }}
              dateFormat={format || "MM/DD/YYYY"}
              timeFormat={false}
              closeOnSelect
              // @ts-ignore
              renderInput={renderDateTimeInput}
            />
          </div>
        );
      case "dropdown":
        return (
          <select
            id={key}
            className={Style.detail_value_input}
            onChange={({ currentTarget }) => {
              onStateChange(key, currentTarget.value, "dropdown");
            }}
            value={stateValue !== undefined ? stateValue : value}
          >
            <option
              key=""
              value={null}
            />
            {options.map((option) => {
              return (
                <option
                  key={option.id}
                  value={option.id}
                >
                  {option.label}
                </option>
              );
            })}
          </select>
        );
      case "search":
        const getOptionsFn = OnboardingV2FunctionMap.item_number;
        const { id, name, identifier } = (value as { id: string; name: string; identifier: string }) || {};
        const defaultValue = id ? { id, label: name || identifier } : {};

        return (
          <div className={Style.search_container}>
            <Selector
              placeholder={editOptions?.placeholder || "Search"}
              onSelect={(selectedOption) => {
                onStateChange(key, selectedOption, "search");
              }}
              value={stateValue.id !== id ? stateValue : defaultValue}
              searchFn={(input: string) => {
                return executeFn(getOptionsFn({ solution: editOptions?.itemTypeClass, type: "search", input }));
              }}
              textareaClassName={Style.search_input}
            />
          </div>
        );
      default:
        const inputValue = String(stateValue === "-" ? "" : stateValue);

        return (
          <TextareaAutosize
            id={key}
            className={`${Style.detail_value_input} ${color ? Style[color] : null}`}
            value={inputValue}
            onChange={({ currentTarget }) => {
              onStateChange(key, currentTarget.value, type.toLowerCase());
            }}
          />
        );
    }
  };

  const renderDateTimeInput = (props: any) => {
    const clear = () => {
      props.onChange({ target: { value: "-" } });
    };
    return (
      <div className={Style.date_time_with_clear}>
        <input {...props} />
        <ClearIcon
          className={Style.clear_icon}
          onClick={clear}
        />
      </div>
    );
  };

  return fields.map((detail: any) => {
    if (detail.type === "button") {
      return isEditing || !isActive ? null : (
        <button
          key={detail.key}
          type="submit"
          className={`${Style.btn} ${detail.light ? Style.white_btn : Style.blue_btn} ${detail.className}`}
          onClick={detail.onClick}
          data-cy-work-order-track-page-detail-card__button={detail.name}
        >
          {detail.name}
        </button>
      );
    }
    return (
      <div
        key={detail.key}
        className={Style.detail_group}
      >
        <p className={Style.detail_label}>{`${detail.name}:`}</p>
        {renderValueField(detail)}
      </div>
    );
  });
};
