import type { Header } from "../../../../../components/paginated-list-table-with-types";
import { VALUE_TYPE_MAP } from "../../../../../components/paginated-list-table-with-types/data/constants";

export const DEFAULT_TRANSFER_ORDER_CONTAINERS_TABLE_HEADERS: Header[] = [
  {
    label: "Pallet #",
    id: "identifier",
    type: VALUE_TYPE_MAP.text,
    isNavLink: true,
    openLinkInNewTab: true
  },
  {
    label: "EPC",
    id: "vid",
    type: VALUE_TYPE_MAP.text
  },
  {
    label: "Status",
    id: "status",
    type: VALUE_TYPE_MAP.status
  },
  {
    label: "Last Updated",
    id: "node_last_updated_time",
    type: VALUE_TYPE_MAP.date
  }
];
